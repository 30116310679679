import * as React from "react";
import { Theme, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, Form, Formik, FormikActions } from "formik";
import V0 from "../../../assets/vest/V0.png";
import V1 from "../../../assets/vest/V1.png";
import V2 from "../../../assets/vest/V2.png";
import V3 from "../../../assets/vest/V3.png";
import V4 from "../../../assets/vest/V4.png";
import V5 from "../../../assets/vest/V5.png";
import V6 from "../../../assets/vest/V6.png";
import V7 from "../../../assets/vest/V7.png";
import { Component } from "react";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import { RouteComponentProps } from "react-router";
import GroupStore from "../../../stores/groupStore";
import ProjectStore from "../../../stores/projectStore";
import LocationStore from "../../../stores/locationStore";
import EndUserStore from "../../../stores/endUserStore";
import * as Yup from "yup";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../../styles/colors";
import Button from "@material-ui/core/Button";
import { CheckboxWithLabel } from "formik-material-ui";

interface MatchParams {
  id: string;
}

interface CustomizationStepProps
  extends WithTranslation,
    InjectedNotistackProps {
  handleNext: (values: any) => void;
  handleBack: () => void;
  handleCancel: () => void;
  values: { v: number };
}

interface InjectedProps
  extends CustomizationStepProps,
    RouteComponentProps<MatchParams> {
  groupStore: GroupStore;
  projectStore: ProjectStore;
  locationStore: LocationStore;
  endUserStore: EndUserStore;
  classes: any;
}

interface CustomizationStepState {
  edit: boolean;
}
const Vests = [V0, V1, V2, V3, V4, V5, V6, V7];

const options: { [key: string]: number } = {
  falsefalsefalse: 0,
  truefalsefalse: 1,
  falsetruefalse: 2,
  falsefalsetrue: 3,
  truetruefalse: 4,
  falsetruetrue: 5,
  truefalsetrue: 6,
  truetruetrue: 7
};

export class CustomizationStepForm {
  constructor(values?: { v: number }) {
    if (values) {
      this.v1 =
        values.v === 1 || values.v === 4 || values.v === 6 || values.v === 7;
      this.v2 =
        values.v === 2 || values.v === 4 || values.v === 5 || values.v === 7;
      this.v3 =
        values.v === 3 || values.v === 5 || values.v === 6 || values.v === 7;
    }
  }
  v1: boolean = false;
  v2: boolean = false;
  v3: boolean = false;
}

const CustomizationStepSchema = Yup.object().shape<CustomizationStepForm>({
  v1: Yup.boolean(),
  v2: Yup.boolean(),
  v3: Yup.boolean()
});

class CustomizationStep extends Component<
  CustomizationStepProps,
  CustomizationStepState
> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    edit: false
  };

  getVest = (values: any) => {
    const { v1, v2, v3 } = values;
    const v: number = options[`${v1}${v2}${v3}`];
    return v;
  };

  submit = async (values: CustomizationStepForm) => {
    const { v1, v2, v3 } = values;
    const v: number = options[`${v1}${v2}${v3}`];
    this.p.handleNext({ v });
  };

  render() {
    const { classes, t, values } = this.p;

    return (
      <Formik
        enableReinitialize
        initialValues={new CustomizationStepForm(values)}
        validationSchema={CustomizationStepSchema}
        onSubmit={(
          values: CustomizationStepForm,
          actions: FormikActions<CustomizationStepForm>
        ) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new CustomizationStepForm();
        }}
      >
        {props => {
          const { values } = props;
          return (
            <Form className={classes.vestForm} noValidate>
              <div className={classes.customizationStepContainer}>
                <div className={classes.vestVersion}>
                  <Field
                    name="v1"
                    Label={{ label: t("MEASUREMENT.V1") }}
                    component={CheckboxWithLabel}
                  />
                  <Field
                    name="v2"
                    Label={{ label: t("MEASUREMENT.V2") }}
                    component={CheckboxWithLabel}
                  />
                  <Field
                    name="v3"
                    Label={{ label: t("MEASUREMENT.V3") }}
                    component={CheckboxWithLabel}
                  />
                </div>
                <div>
                  <img
                    className={classes.imgVest}
                    src={Vests[this.getVest(values)]}
                    alt="vest version"
                  />
                </div>
              </div>
              <div className={classes.btnContainer}>
                <div className={classes.leftButton}>
                  <Button
                    onClick={this.p.handleCancel}
                    className={classes.button}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
                <div className={classes.rightButton}>
                  <Button
                    onClick={this.p.handleBack}
                    className={classes.button}
                  >
                    {t("BACK")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.buttonNext}
                  >
                    {t("NEXT")}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: "#fff",
      position: "absolute",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    },

    formControl: {
      background: "rgba(255, 255, 255, 0.12)"
    },

    stepContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },

    textField: {
      width: "100%",
      maxWidth: 380
    },

    customizationStepContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      "@media (max-width:1200px)": {
        flexDirection: "column"
      }
    },

    stepperContainer: {
      display: "flex",
      flexDirection: "row",
      "@media (max-width:1200px)": {
        flexDirection: "column",
        alignItems: "center"
      }
    },

    btnContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "calc(100% - 372px)",
      position: "fixed",
      bottom: 40,
      "@media (max-width:1200px)": {
        padding: 20,
        position: "relative",
        bottom: 0,
        width: "100%"
      }
    },

    leftButton: {
      flex: 1
    },

    rightButton: {
      flex: 1,
      textAlign: "right",
      "@media (max-width:500px)": {
        display: "flex",
        flexDirection: "row"
      }
    },

    button: {
      width: "20%"
    },

    buttonNext: {
      marginLeft: 20,
      width: "20%",
      "@media (max-width:1200px)": {
        marginLeft: 10
      }
    },

    imgVest: {
      maxWidth: 500,
      width: "100%",
      height: "auto"
    },

    vestForm: {
      display: "flex",
      flexDirection: "column"
    },

    vestVersion: {
      display: "flex",
      flexDirection: "column",
      "@media (max-width:1200px)": {
        marginRight: 0,
        marginBottom: 30
      }
    },

    vestDescribe: {
      width: "100%",
      maxWidth: 400,
      textAlign: "center"
    }
  });

export default withStyles(styles)(
  withTranslation()(withSnackbar(CustomizationStep))
);
