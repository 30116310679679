import { action, configure, observable } from "mobx";
import userService from "../services/userService";

configure({ enforceActions: "observed" });

type Project = {
  name: string;
  id: string;
};

export type UserBase = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  username: string;
  active: boolean | string;
  projects: Project[];
  extendedMeasurement: boolean;
};

export interface User extends UserBase {
  id: string;
  username: string;
}

export default class UserStore {
  // STORE
  @observable user: User = {
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    username: "",
    active: false,
    projects: [],
    extendedMeasurement: false
  };

  @observable pending: boolean = false;

  @observable userList: User[] = [];

  @observable usersTotalCount?: number;

  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  resetUser = () => {
    this.setUser({
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      username: "",
      active: false,
      projects: [],
      extendedMeasurement: false
    });
  };

  // ACTIONS

  @action
  private setUser = (user: User) => {
    this.user = user;
  };

  @action
  private setUserList = (userList: User[]) => {
    this.userList = userList;
  };
  @action
  private setTotalCount = (totalCount: number) => {
    this.usersTotalCount = totalCount;
  };

  @action
  getUserListAction = async (params?: any) => {
    this.pendingStart();
    const result = await userService.getUsers(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setUserList(result.users);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  addUserAction = async (data: any) => {
    this.pendingStart();
    const result = await userService.addUser({
      ...data,
      type: "MODERATOR",
      username: data.email
    });
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getUserListAction();
    return true;
  };

  @action
  getUserByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await userService.getUserById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setUser(result.user);
    return true;
  };

  @action
  editUserAction = async (data: any, id: string) => {
    this.pendingStart();
    const result = await userService.editUser(
      { ...data, type: "MODERATOR", username: data.email },
      id
    );
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  deleteUserAction = async (id: string) => {
    this.pendingStart();
    const result = await userService.deleteUser(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
}
