import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../styles/colors";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { RouteComponentProps } from "react-router";
import List from "../List";
import GroupStore, { Group, GroupLI } from "../../stores/groupStore";
import { inject, observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Field, Formik, FormikActions } from "formik";
import * as Yup from "yup";
import { StringValidator } from "../../utils/validators";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableRow from "@material-ui/core/TableRow";
import { DialogComponent } from "../DialogComponent";
import Filters from "../Filters";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AppStore from "../../stores/appStore";
import LocationStore, { Location } from "../../stores/locationStore";
import { AutocompleteMultiple } from "../AutocompleteNew";
import { toJS } from "mobx";

interface MatchParams {
  id: string;
}

interface GroupListProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps
  extends GroupListProps,
    RouteComponentProps<MatchParams> {
  groupStore: GroupStore;
  appStore: AppStore;
  locationStore: LocationStore;
  classes: any;
}

interface GroupListState {
  open: boolean;
  edit: string;
  add: boolean;
  openDeleteDialog: string;
  filters: {
    [key: string]: string;
  };
  sortParams: {
    [key: string]: string;
  };
}

class GroupForm {
  constructor(group?: GroupLI) {
    if (group) {
      this.name = group.name;
      this.active = group.active;
      this.locations = group.locations.map((item: any) => item);
    }
  }

  name: string = "";
  active: boolean = true;
  locations: string[] = [];
}

const GroupSchema = Yup.object().shape<GroupForm>({
  name: StringValidator,
  active: Yup.boolean(),
  locations: Yup.array()
    .of(Yup.string())
    .required()
});

@inject("groupStore", "appStore", "locationStore")
@observer
class GroupList extends Component<GroupListProps, GroupListState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    open: false,
    add: false,
    edit: "",
    openDeleteDialog: "",
    filters: {},
    sortParams: {}
  };

  filtersList = [
    {
      name: "name",
      label: this.p.t("GROUP_LIST.NAME"),
      type: "input"
    }
  ];

  headers = [
    {
      name: this.p.t("GROUP_LIST.NAME"),
      key: "name",
      sort: true
    },
    {
      name: this.p.t("GROUP_LIST.ACTIONS"),
      key: "actions"
    }
  ];

  getGroupList = (sortParams: any) => {
    if (Object.keys(sortParams).length) {
      this.setState({ sortParams });
    }
    const sort = { ...this.state.sortParams, ...sortParams };
    const params = {
      ...sort,
      ...this.state.filters
    };
    this.p.groupStore.getGroupListAction(params);
  };

  handleAddGroup = () => {
    this.setState({ add: true, edit: "", open: true });
  };
  handleClose = () => {
    this.setState({ openDeleteDialog: "", edit: "", add: false, open: false });
    this.p.groupStore.resetGroup();
  };

  handleOpenGroup = (uuid: string) => () => {
    this.setState({ edit: uuid });
    this.p.groupStore.getGroupByIdAction(uuid);
  };

  handleDeleteGroup = (uuid: string) => () => {
    this.setState({ openDeleteDialog: uuid });
  };

  submit = async (values: GroupForm) => {
    if (this.state.edit) {
      this.editGroup(values);
    } else {
      this.addGroup(values);
    }
  };

  addGroup = async (values: GroupForm) => {

    const success = await this.p.groupStore.addGroupAction(values);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.CANT_ADD_GROUP"), {
        variant: "error"
      });
    } else {
      this.p.enqueueSnackbar(this.p.t("GROUP_LIST.ADD_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/groups");
    }
    this.handleClose();
    this.getGroupList({});
  };

  deleteGroup = async () => {
    const id = this.state.openDeleteDialog;
    const success = await this.p.groupStore.deleteGroupAction(id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_DELETE_GROUP"), {
        variant: "error"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("GROUP_LIST.DELETE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/groups");
    }
    this.handleClose();
    this.getGroupList({});
  };

  editGroup = async (values: GroupForm) => {
    const id = this.state.edit;
    const success = await this.p.groupStore.editGroupAction(values, id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_EDIT_GROUP"), {
        variant: "error"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("GROUP_LIST.UPDATE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/groups");
    }
    this.handleClose();
    this.getGroupList({});
  };

  handleChange = (filters: any) => {
    this.setState({ filters }, () => this.getGroupList({}));
  };

  getLocationsOptions = async (search: string) => {
    await this.p.locationStore.getLocationOptionsAutocompleteAction({
      name: search
    });
  };

  render() {
    const {
      classes,
      t,
      groupStore: { groupList, groupsTotalCount, pending, group },
      appStore: { isFilter },
      locationStore: { locationOptionsAutocomplete }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography
          className={classNames(classes.dividerFullWidth, "title")}
          display="block"
          variant="subtitle1"
        >
          {t("GROUP_LIST.TITLE")}
        </Typography>
        <Divider component="div" className={"divider"} />
        <Filters handleFilter={this.handleChange} filters={this.filtersList} />
        <List
          data={groupList}
          getData={this.getGroupList}
          headers={this.headers}
          isFilter={isFilter}
          totalCount={groupsTotalCount}
          headerClass={classes.headerPadding}
        >
          {groupList &&
            groupList.length &&
            groupList.map((item: any, i: number) => (
              <TableRow key={item.uuid || item.id || i} className={"rowList"}>
                <TableCell className={classes.tableCell}>{item.name}</TableCell>
                <TableCell className={classes.cellActions}>
                  <EditIcon
                    onClick={this.handleOpenGroup(item.id)}
                    className={classes.iconClick}
                  />
                  <DeleteIcon
                    className={classNames(
                      classes.iconMargin,
                      classes.iconClick
                    )}
                    onClick={this.handleDeleteGroup(item.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </List>
        <Tooltip title="Add" placement="top-start">
          <Fab
            color="primary"
            aria-label="Add"
            className={classNames(classes.fab, classes.addButton)}
            onClick={this.handleAddGroup}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <Dialog
          open={!!this.state.edit || this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          disableBackdropClick={true}
          PaperProps={{
            classes: {
              root: classes.groupDialog
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Formik
            enableReinitialize
            initialValues={
              new GroupForm(
                groupList.find((item: any) => item.id === this.state.edit)
              )
            }
            validationSchema={GroupSchema}
            onSubmit={(
              values: GroupForm,
              actions: FormikActions<GroupForm>
            ) => {
              this.submit(values).then(() => {
                actions.setSubmitting(false);
              });
            }}
            onReset={values => {
              values = new GroupForm();
            }}
          >
            {props => {
              const {
                values,
                setFieldValue,
                errors,
                setFieldTouched,
                touched
              } = props;
              return (
                <>
                  <DialogTitle>
                    {!!this.state.add && t("GROUP_LIST.ADD_GROUP")}{" "}
                    {!!this.state.edit && t("GROUP_LIST.EDIT_GROUP")}
                  </DialogTitle>
                  <DialogContent className={classes.groupDialogHeight}>
                    <DialogContentText id="alert-dialog-description" />
                    <Field
                      id="name"
                      name="name"
                      label={t("GROUP_LIST.NAME")}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      component={TextField}
                      className={classNames(
                        classes.textField,
                        classes.formControl
                      )}
                      margin="normal"
                      autoComplete="off"
                      variant="outlined"
                    />

                    <AutocompleteMultiple
                      error={errors.locations}
                      touched={touched.locations}
                      label={t("PROJECT.LOCATIONS")}
                      options={locationOptionsAutocomplete}
                      // disabled={!this.state.edit}
                      onInputChange={this.getLocationsOptions}
                      onValueChange={(value: any) => {
                        setFieldTouched("locations");
                        setFieldValue("locations", value);
                      }}
                      id="locations"
                      name="locations"
                      required
                      initialValue={
                        group.locations &&
                        group.locations.map((item: Location) => ({
                          label: item.name,
                          value: item.id
                        }))
                      }
                    />

                    <div>
                      <Field
                        name="active"
                        Label={{ label: t("LOCATION_LIST.ACTIVE") }}
                        component={CheckboxWithLabel}
                      />{" "}
                    </div>
                  </DialogContent>
                  <DialogActions className={classes.btnGroupDialog}>
                    <Button
                      onClick={props.submitForm}
                      color="primary"
                      variant="contained"
                      className={classes.btn}
                    >
                      {t("SAVE")}
                    </Button>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      variant="contained"
                      className={classes.btn}
                    >
                      {t("CANCEL")}
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Dialog>
        <DialogComponent
          title={"GROUP_LIST.GROUP_DELETE"}
          t={t}
          classes={classes}
          submitButtonTitle={"DELETE"}
          cancel={"CANCEL"}
          pending={pending}
          handleClickCloseDialog={this.handleClose}
          handleDelete={this.deleteGroup}
          open={!!this.state.openDeleteDialog}
        />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: "100%",
      position: "relative",
      height: "calc(100vh - 90px)"
    },

    addButton: {
      color: "#fff",
      position: "fixed",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: 40
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    },

    formControl: {
      background: "rgba(255, 255, 255, 0.12)",
      width: "70%"
    },

    btn: {
      color: COLORS.WHITE,
      background: COLORS.PRIMARY_DARK,
      width: "20%"
    },

    groupDialog: {
      textAlign: "center",
      background: COLORS.DIALOG_COLOR,
      borderRadius: 10
    },

    groupDialogHeight: {
      height: 700
    },

    btnGroupDialog: {
      justifyContent: "center"
    },

    cellActions: {
      maxWidth: 100,
      padding: "16px 5px",
      textAlign: "left",
      width: 100
    },

    iconMargin: {
      marginLeft: 20
    },

    btnColor: {
      color: COLORS.WHITE,
      "&:hover": {
        background: COLORS.PRIMARY_DARK
      }
    },
    iconClick: {
      cursor: "pointer"
    },

    headerPadding: {
      padding: "10px 20px 10px 10px;",
      textAlign: "left"
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(GroupList)));
