import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Theme, TableCell } from "@material-ui/core";
import { withSnackbar } from "notistack";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithTranslation, withTranslation } from "react-i18next";
import AdminStore from "../../../stores/adminStore";
import { InjectedNotistackProps } from "notistack";
import { RouteComponentProps } from "react-router";
import DropZone from "./DropZone";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import List, { Header } from "../../List";
import TableRow from "@material-ui/core/TableRow";

interface MatchParams {}

interface AccountProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends AccountProps, RouteComponentProps<MatchParams> {
  adminStore: AdminStore;
  classes: any;
  userId: string;
  location: any;
}

interface UploadSizesState {
  sortParams: {
    [key: string]: string;
  };
}

@inject("adminStore")
@observer
class MeasurementsData extends Component<AccountProps, UploadSizesState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    sortParams: {}
  };

  headers = [
    {
      name: this.p.t("FILE_LIST.USER"),
      key: "user",
      sort: true
    },
    {
      name: this.p.t("FILE_LIST.CREATED_DATE"),
      key: "createdDate",
      sort: true
    },
    {
      name: this.p.t("FILE_LIST.STATUS"),
      key: "status"
    }
  ];

  sendFile = async (data: any) => {
    const success = await this.p.adminStore.uploadFileAction(data[0]);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.UPLOAD_ERROR"), {
        variant: "error"
      });
    } else {
      this.p.enqueueSnackbar(this.p.t("ERRORS.UPLOAD_SUCCESS"), {
        variant: "success"
      });
    }
  };

  getFileList = (sortParams: any) => {
    if (Object.keys(sortParams).length) {
      this.setState({ sortParams });
    }
    const sort = { ...this.state.sortParams, ...sortParams };
    const params = {
      ...sort
    };
    this.p.adminStore.getFileListAction(params);
  };

  render() {
    const {
      classes,
      t,
      adminStore: { importList, importTotalCount }
    } = this.p;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography
            className={classNames(classes.dividerFullWidth, "title")}
            color="textSecondary"
            display="block"
            variant="subtitle1"
          >
            {t("FILE_LIST.UPLOAD_XLSX")}
          </Typography>
          <Divider component="div" className={"divider"} />
          <DropZone send={this.sendFile} />
        </div>
        <Typography
          className={classNames(classes.dividerFullWidth, "title")}
          display="block"
          variant="subtitle1"
        >
          {t("FILE_LIST.TITLE")}
        </Typography>
        <Divider component="div" className={"divider"} />
        <List
          data={importList}
          getData={this.getFileList}
          headers={this.headers}
          totalCount={importTotalCount}
          headerClass={classes.sizeTable}
        >
          {importList &&
            importList.length &&
            importList.map((item: any, i: number) => (
              <TableRow key={item.uuid || item.id || i} className={"rowList"}>
                {this.headers.map((header: Header) => {
                  switch (header.key) {
                    case "user":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item[header.key].username}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item[header.key]}
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
        </List>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      backgroundSize: "cover",
      flexDirection: "column"
    },

    content: {
      width: "100%",
      padding: 20
    },

    cardContainer: {
      background: "transparent",
      display: "flex",
      flexDirection: "column",
      width: "30%",
      margin: "0 auto",
      boxShadow: "none"
    },

    sizeTable: {
      padding: "10px 20px 10px 10px",
      textAlign: "left"
    }
  });

export default withStyles(styles)(
  withTranslation()(withSnackbar(MeasurementsData))
);
