import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { COLORS } from "../../styles/colors";
import { TextField } from "formik-material-ui";
import { Formik, FormikActions, Form, Field } from "formik";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  EmailValidator,
  PhoneValidator,
  StringValidator
} from "../../utils/validators";
import AdminStore, { Admin } from "../../stores/adminStore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { DialogComponent } from "../DialogComponent";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import {dataToUpperCase} from "../../utils/dataToUppercase"

interface MatchParams {
  id: string;
}

interface AdminDetailsProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps
  extends AdminDetailsProps,
    RouteComponentProps<MatchParams> {
  adminStore: AdminStore;
  classes: any;
  adminId: string;
}

class AdminForm {
  constructor(admin?: Admin) {
    if (admin) {
      this.firstName = admin.firstName;
      this.lastName = admin.lastName;
      this.email = admin.email;
      this.phone = admin.phone;
      this.active = admin.active.toString();
    }
  }

  firstName: string = "";
  lastName: string = "";
  email: string = "";
  phone: string = "";
  active: string = "false";
}

const AdminSchema = Yup.object().shape<AdminForm>({
  firstName: StringValidator,
  lastName: StringValidator,
  email: EmailValidator,
  phone: PhoneValidator,
  active: StringValidator
});

interface AdminDetailsState {
  edit: boolean;
  open: boolean;
  add: boolean;
}

@inject("adminStore")
@observer
class AdminDetails extends Component<AdminDetailsProps, AdminDetailsState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    edit: !this.p.match.params.id,
    open: false,
    add: false
  };

  componentDidMount(): void {
    this.getAdminDetails();
    this.setState({ add: !this.state.edit });
  }

  componentWillUnmount(): void {
    this.p.adminStore.resetAdmin();
  }

  getAdminDetails = async () => {
    if (this.p.match.params.id) {
      const success = await this.p.adminStore.getAdminByIdAction(
        this.p.match.params.id
      );
      if (!success) {
        this.p.enqueueSnackbar(this.p.t("ERRORS.CANT_GET_ADMIN"), {
          variant: "error"
        });
      }
    }
  };

  submit = async (values: AdminForm) => {
    dataToUpperCase(values);
    if (this.p.match.params.id) {
      this.editAdmin(values);
    } else {
      this.addAdmin(values);
    }
  };

  addAdmin = async (values: AdminForm) => {
    const success = await this.p.adminStore.addAdminAction(values);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.CANT_ADD_ADMIN"), {
        variant: "error"
      });
    } else {
      this.p.enqueueSnackbar(this.p.t("ADMIN.ADD_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/admins");
    }
  };

  editAdmin = async (values: AdminForm) => {
    const id = this.p.match.params.id;
    const success = await this.p.adminStore.editAdminAction(values, id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_EDIT_ADMIN"), {
        variant: "error"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("ADMIN.UPDATE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/admins");
    }
  };

  enableEdit = (
    resetForm: (values: any) => void,
    values: any,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    resetForm(values);
    this.setState({ edit: true });
  };

  disableEdit = () => {
    this.setState({ edit: false });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  deleteAdmin = async () => {
    const id = this.p.match.params.id;
    const success = await this.p.adminStore.deleteAdminAction(id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_DELETE_ADMIN"), {
        variant: "error"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("ADMIN.DELETE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/admins");
    }
  };

  backToList = () => {
    this.p.history.goBack();
  };

  render() {
    const {
      classes,
      t,
      adminStore: { pending, admin }
    } = this.p;
    const adminId = this.p.match.params.id;
    return (
      <Formik
        enableReinitialize
        initialValues={new AdminForm(admin)}
        validationSchema={AdminSchema}
        onSubmit={(values: AdminForm, actions: FormikActions<AdminForm>) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new AdminForm();
        }}
      >
        {props => {
          const { setFieldTouched, setFieldValue, values, errors } = props;
          return (
            <Form noValidate>
              <div className={"titleContainer"}>
                <BackIcon className={"backList"} onClick={this.backToList} />
                <Typography
                  className={classNames(classes.dividerFullWidth, "title")}
                  display="block"
                  variant="subtitle1"
                >
                  {t("ADMIN.TITLE")}
                </Typography>
              </div>
              <Divider component="div" className={"divider"} />
              <div className={classes.container}>
                <div className={classes.content}>
                  <Card
                    className={classNames(classes.card, classes.cardContainer)}
                  >
                    <CardContent className={classes.cardContent}>
                      <Field
                        id="firstName"
                        name="firstName"
                        disabled={!this.state.edit}
                        label={t("ADMIN.NAME")}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl,
                            input: classes.transformText
                          }
                        }}
                        component={TextField}
                        className={classes.textField}
                        margin="normal"
                        autoComplete="off"
                        variant="outlined"
                      />
                      <Field
                        id="lastName"
                        name="lastName"
                        disabled={!this.state.edit}
                        label={t("ADMIN.SURNAME")}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl,
                            input: classes.transformText
                          }
                        }}
                        component={TextField}
                        className={classes.textField}
                        margin="normal"
                        autoComplete="off"
                        variant="outlined"
                      />
                      <Field
                        id="email"
                        name="email"
                        disabled={!this.state.edit}
                        label={t("ADMIN.EMAIL")}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl
                          }
                        }}
                        component={TextField}
                        className={classes.textField}
                        margin="normal"
                        autoComplete="off"
                        variant="outlined"
                      />
                      <Field
                        id="phone"
                        name="phone"
                        disabled={!this.state.edit}
                        label={t("ADMIN.PHONE_NUMBER")}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl
                          }
                        }}
                        component={TextField}
                        className={classes.textField}
                        margin="normal"
                        autoComplete="off"
                        variant="outlined"
                      />
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                      {adminId && (
                        <>
                          {!this.state.edit ? (
                            <>
                              <Button
                                aria-label="Edit"
                                type="button"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                onClick={event =>
                                  this.enableEdit(
                                    props.resetForm,
                                    props.values,
                                    event
                                  )
                                }
                                disabled={pending}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("EDIT")
                                )}
                              </Button>
                              <Button
                                aria-label="Delete"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                onClick={this.handleClickOpenDialog}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("DELETE")
                                )}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                aria-label="Save"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                type="submit"
                                disabled={pending}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("SAVE")
                                )}
                              </Button>
                              <Button
                                aria-label="Delete"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                onClick={this.disableEdit}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("CANCEL")
                                )}
                              </Button>
                            </>
                          )}
                          <DialogComponent
                            title={"ADMIN.ADMIN_DELETE"}
                            t={t}
                            classes={classes}
                            submitButtonTitle={"DELETE"}
                            cancel={"CANCEL"}
                            pending={pending}
                            handleClickCloseDialog={this.handleCloseDialog}
                            handleDelete={this.deleteAdmin}
                            open={this.state.open}
                          />
                        </>
                      )}
                      {!this.p.match.params.id && (
                        <Button
                          aria-label="Save"
                          className={classNames(classes.fab, classes.btn)}
                          type="submit"
                        >
                          {pending ? (
                            <CircularProgress
                              size={20}
                              thickness={5}
                              color="primary"
                            />
                          ) : (
                            t("SAVE")
                          )}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      backgroundSize: "cover"
    },

    transformText: {
      textTransform: "uppercase"
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      maxWidth: 380,
      marginTop: 12
    },
    customSelect: {
      height: 56
    },
    select: {
      width: "100%",
      maxWidth: 380,
      height: 56,
      marginTop: 10,
      borderRadius: 5,
      borderColor: COLORS.WHITE,
      background: "rgba(255, 255, 255, 0.12)"
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    },

    formControl: {
      background: "rgba(255, 255, 255, 0.12)"
    },

    content: {
      width: "100%",
      padding: 20,

      "@media (max-width:1200px)": {
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }
    },

    cardContainer: {
      background: "transparent",
      display: "flex",
      flexDirection: "column",
      width: "80%",
      margin: "0 auto",
      boxShadow: "none",
      overflow: "visible"
    },

    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },

    btn: {
      margin: "0 10px",
      width: "10%",
      background: COLORS.PRIMARY_DARK,
      color: COLORS.WHITE
    },

    cardAction: {
      justifyContent: "center"
    },
    btnColor: {
      color: COLORS.WHITE,
      "&:hover": {
        background: COLORS.PRIMARY_DARK
      }
    }
  });

export default withStyles(styles)(
  withTranslation()(withSnackbar(AdminDetails))
);
