import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../styles/colors";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { RouteComponentProps } from "react-router";
import List, { Header } from "../List";
import { inject, observer } from "mobx-react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Female from "../../assets/sex/gender-female.svg";
import Male from "../../assets/sex/gender-male.svg";
import ProjectStore from "../../stores/projectStore";
import GroupStore from "../../stores/groupStore";
import LocationStore from "../../stores/locationStore";
import MeasurementStore from "../../stores/measurementStore";
import Tooltip from "@material-ui/core/Tooltip";

interface MatchParams {}

interface EndUserListProps extends WithTranslation {}

interface InjectedProps
  extends EndUserListProps,
    RouteComponentProps<MatchParams> {
  projectStore: ProjectStore;
  groupStore: GroupStore;
  locationStore: LocationStore;
  measurementStore: MeasurementStore;
  classes: any;
}

interface EndUserListState {
  filters: {
    [key: string]: string;
  };
}

@inject("groupStore", "locationStore", "projectStore", "measurementStore")
@observer
class MeasurementsList extends Component<EndUserListProps, EndUserListState> {
  get p() {
    return this.props as InjectedProps;
  }

  headers = [
    {
      name: this.p.t("END_USER_LIST.NAME"),
      key: "firstName"
    },
    {
      name: this.p.t("END_USER_LIST.SURNAME"),
      key: "lastName",
      sort: true
    },
    {
      name: this.p.t("END_USER_LIST.PID"),
      key: "pid"
    },
    {
      name: this.p.t("END_USER_LIST.PROJECT"),
      key: "project"
    },
    {
      name: this.p.t("END_USER_LIST.GROUP"),
      key: "group"
    },
    {
      name: this.p.t("END_USER_LIST.LOCATION"),
      key: "location"
    },
    {
      name: this.p.t("END_USER_LIST.GENDER"),
      key: "gender"
    },

    {
      name: this.p.t("END_USER_LIST.CHEST_HEIGHT"),
      key: "chestHeight"
    },
    {
      name: this.p.t("END_USER_LIST.BREASTS_UNDER"),
      key: "breastsUnder"
    },
    {
      name: this.p.t("END_USER_LIST.CHOSEN_ID"),
      key: "chosenId"
    },
    {
      name: this.p.t("END_USER_LIST.CHOSEN_LABEL"),
      key: "chosenLabel"
    },
    {
      name: this.p.t("END_USER_LIST.HEIGHT"),
      key: "height"
    },
    {
      name: this.p.t("END_USER_LIST.SUGGESTED_LABEL"),
      key: "suggestedLabel"
    },
    {
      name: this.p.t("END_USER_LIST.V"),
      key: "v"
    },
    {
      name: this.p.t("END_USER_LIST.WAIST"),
      key: "waist"
    },
    {
      name: this.p.t("END_USER_LIST.BACK_CHOSEN_ID"),
      key: "backChosenId"
    },
    {
      name: this.p.t("END_USER_LIST.BACK_CHOSEN_LABEL"),
      key: "backChosenLabel"
    },
    {
      name: this.p.t("END_USER_LIST.BREASTS_LABEL"),
      key: "breastsLabel"
    },
    {
      name: this.p.t("END_USER_LIST.CHEST_WIDTH"),
      key: "chestWidth"
    },
    {
      name: this.p.t("END_USER_LIST.SUGGESTED_BREASTS_LABEL"),
      key: "suggestedBreastsLabel"
    },
    {
      name: this.p.t("END_USER_LIST.CREATED_DATE"),
      key: "createdDate"
    },
    {
      name: this.p.t("END_USER_LIST.CREATED_BY"),
      key: "createdBy"
    }
  ];

  getMeasurementList = (sortParams: any) => {
    const params = {
      ...sortParams
    };
    this.p.measurementStore.getMeasurementListAction(params);
  };

  handleOpenMeasurement = (id: string) => () => {
    this.p.history.push(`/measurements/details/${id}`);
  };

  handleAddMeasurement = () => {
    this.p.history.push(`/measurements/add`);
  };

  render() {
    const {
      classes,
      t,
      measurementStore: { measurementList, measurementsTotalCount }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography
          className={classNames(classes.dividerFullWidth, "title")}
          display="block"
          variant="subtitle1"
        >
          {t("MEASUREMENT_LIST.TITLE")}
        </Typography>
        <Divider component="div" className={"divider"} />
        <List
          data={measurementList}
          getData={this.getMeasurementList}
          headers={this.headers}
          totalCount={measurementsTotalCount}
          headerClass={classes.headerPadding}
        >
          {measurementList &&
            measurementList.length &&
            measurementList.map((item: any, i: number) => (
              <TableRow
                key={item.uuid || item.id || i}
                className={"rowList"}
                onClick={this.handleOpenMeasurement(item.id)}
              >
                {this.headers.map((header: Header) => {
                  switch (header.key) {
                    case "firstName":
                    case "lastName":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            "capitalizeText"
                          )}
                        >
                          {item.person && item.person[header.key]}
                        </TableCell>
                      );
                    case "pid":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item.person && item.person[header.key]}
                        </TableCell>
                      );
                    case "gender":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item.person &&
                          item.person[header.key] === "FEMALE" ? (
                            <img
                              className={classes.genderColor}
                              src={Female}
                              alt="female"
                            />
                          ) : (
                            <img
                              className={classes.genderColor}
                              src={Male}
                              alt="male"
                            />
                          )}
                        </TableCell>
                      );
                    case "project":
                    case "group":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item && item[header.key].name}
                        </TableCell>
                      );
                    case "location":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.location
                          )}
                        >
                          {item && item[header.key].name}
                        </TableCell>
                      );
                    case "chestHeight":
                    case "chestWidth":
                    case "chosenId":
                    case "chosenLabel":
                    case "height":
                    case "suggestedLabel":
                    case "v":
                    case "waist":
                    case "backChosenId":
                    case "backChosenLabel":
                    case "breastsLabel":
                    case "breastsUnder":
                    case "suggestedBreastsLabel":
                    case "createdDate":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item && item[header.key] ? item[header.key] : "-"}
                        </TableCell>
                      );
                    case "createdBy":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.createdBy
                          )}
                        >
                          {item && item[header.key].username}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item[header.key]}
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
        </List>
        <Tooltip title="Add" placement="top-start">
          <Fab
            color="primary"
            aria-label="Add"
            className={classNames(classes.fab, classes.addButton)}
            onClick={this.handleAddMeasurement}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: "100%",
      position: "relative",
      height: "calc(100vh - 90px)"
    },

    rowList: {
      cursor: "pointer"
    },

    addButton: {
      color: "#fff",
      position: "fixed",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: 40
    },
    genderColor: {
      color: COLORS.WHITE
    },
    tableCell: {
      textAlign: "center"
    },

    headerPadding: {
      textAlign: "center"
    },

    location: {
      minWidth: 250,
      maxWidth: 315
    },

    measurement: {
      minWidth: 80,
      maxWidth: 120
    },

    createdBy: {
      minWidth: 250,
      maxWidth: 315
    }
  });

export default withStyles(styles)(withTranslation()(MeasurementsList));
