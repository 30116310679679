import REST from "../constants/rest";
import API from "./apiService";

export default class MeasurementService {
  static async getMeasurements(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/measurements?include=group,project,location,person,creator${
      params ? "&" + urlSP.toString() : ""
    }`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getEndUserMeasurements(id?: string, params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/measurements?person=${id}&include=creator${
      params ? "&" + urlSP.toString() : ""
    }`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getMeasurementById(id: string) {
    const path = `/measurements/${id}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addMeasurement(data: any, personId: string) {
    const path = `/persons/${personId}/measurements`;
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editMeasurement(params: any, id: string) {
    const path = `/persons/${id}`;
    const options = {
      method: REST.POST,
      body: params
    };
    return API.fetch(path, options);
  }

  static async deleteMeasurement(id: string) {
    const path = `/measurements/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }

  static async getSuggestions(data: any) {
    const path = `/measurements/suggestion`;
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }
}
