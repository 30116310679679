import { action, configure, observable } from "mobx";
import projectService from "../services/projectService";
import { Group, GroupLI } from "./groupStore";
import { Location } from "./locationStore";

configure({ enforceActions: "observed" });

interface ProjectBase {
  name: string;
  type: string;
}

interface ProjectOption {
  label: string;
  value: string;
}

export interface Project extends ProjectBase {
  id: string;
  groups: Group[];
  locations: Location[];
}
export interface ProjectLI extends ProjectBase {
  id: string;
  groups: string[];
  locations: string[];
}

export default class ProjectStore {
  // STORE
  @observable
  project: Project = {
    id: "",
    name: "",
    type: "",
    groups: [],
    locations: []
  };

  @observable pending: boolean = false;

  @observable projectList: ProjectLI[] = [];
  @observable projectOptionsAutocomplete: ProjectOption[] = [];

  @observable projectsTotalCount?: number;

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  // ACTIONS

  @action
  private setProject = (project: Project) => {
    this.project = project;
  };

  @action
  private setProjectList = (projectList: ProjectLI[]) => {
    this.projectList = projectList;
  };

  @action
  private setProjectOptions = (projectList: ProjectLI[]) => {
    this.projectOptionsAutocomplete = projectList.map((item: ProjectLI) => ({
      label: item.name,
      value: item.id
    }));
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.projectsTotalCount = totalCount;
  };

  @action
  resetProject = () => {
    this.project = {
      id: "",
      name: "",
      type: "",
      groups: [],
      locations: []
    };
  };

  @action
  getProjectListAction = async (params: any) => {
    this.pendingStart();
    const result = await projectService.getProjects(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setProjectList(result.projects);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  getProjectOptionsAutocompleteAction = async (params?: any) => {
    this.pendingStart();
    const result = await projectService.getProjects(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setProjectOptions(result.projects);
    return true;
  };

  @action
  getProjectByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await projectService.getProjectById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setProject(result.project);
    return true;
  };

  @action
  addProjectAction = async (data: any) => {
    this.pendingStart();
    const result = await projectService.addProject(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getProjectListAction({});
    return true;
  };

  @action
  editProjectAction = async (params: any, id: string) => {
    this.pendingStart();
    // const result = await projectService.editProject(params, id);
    const result = await projectService.editProject(params, id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  deleteProjectAction = async (id: string) => {
    this.pendingStart();
    const result = await projectService.deleteProject(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
}
