import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { withStyles } from "@material-ui/core";
import AuthStore from "../stores/authStore";
import AppStore from "../stores/appStore";
import HomeContainer from "./HomeContainer";
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import SetPassword from "./auth/SetPassword";
interface PrivateRouterProps {
  component: any;
  loggedIn: boolean;
  path: string;
}
const PrivateRoute = ({
  component: Component,
  loggedIn,
  ...rest
}: PrivateRouterProps) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

interface AppRouterProps extends RouteComponentProps<any> {}
interface InjectedProps extends AppRouterProps {
  authStore: AuthStore;
  appStore: AppStore;
  classes: any;
  enqueueSnackbar: any;
  location: any;
}

@inject("authStore", "appStore")
@observer
class AppRouter extends Component<AppRouterProps> {
  get p() {
    return this.props as InjectedProps;
  }
  componentDidMount() {
    this.p.authStore.autoLoginAction();
  }

  componentWillReceiveProps(nextProps: InjectedProps, nextContext: any) {
    if (this.p.authStore.session && !nextProps.authStore.session) {
      this.p.enqueueSnackbar("Session expired! Please login again.", {
        variant: "warning"
      });
    }
  }

  renderRoutes = () => {
    const { session } = this.p.authStore;
    const { location } = this.p;
    return (
      <TransitionGroup>
        <Switch location={location}>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/activate/:token" component={SetPassword} />
          <Route path="/reset-password/:token" component={SetPassword} />
          <PrivateRoute
            loggedIn={session && session.xsrfToken}
            path="/"
            component={HomeContainer}
          />
          <Redirect to="/" />
        </Switch>
      </TransitionGroup>
    );
  };

  render() {
    const {
      appStore: { initialized }
    } = this.p;

    return <>{!initialized ? <p>Loading</p> : <>{this.renderRoutes()}</>}</>;
  }
}

const styles = (theme: any) => ({});

export default withStyles(styles)(withRouter<AppRouterProps>(AppRouter));
