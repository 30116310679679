import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../styles/colors";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { RouteComponentProps } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import UserStore, { User } from "../../stores/userStore";
import List, { Header } from "../List";
import { inject, observer } from "mobx-react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ActiveIcon from "@material-ui/icons/Check";
import InactiveIcon from "@material-ui/icons/Close";
import Filters from "../Filters";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import AppStore from "../../stores/appStore";

interface MatchParams {
  history: string;
}

interface UserListProps extends WithTranslation {}

interface InjectedProps
  extends UserListProps,
    RouteComponentProps<MatchParams> {
  userStore: UserStore;
  appStore: AppStore;
  classes: any;
}

interface UserListState {
  filters: {
    [key: string]: string;
  };
  sortParams: {
    [key: string]: string;
  };
}

@inject("userStore", "appStore")
@observer
class UsersList extends Component<UserListProps, UserListState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    filters: {},
    sortParams: {}
  };

  filtersList = [
    {
      name: "lastName",
      label: this.p.t("USER_LIST.SURNAME"),
      type: "input"
    },
    {
      name: "email",
      label: this.p.t("USER_LIST.EMAIL"),
      type: "input"
    },
    {
      name: "phone",
      label: this.p.t("USER_LIST.PHONE_NUMBER"),
      type: "input"
    },
    {
      name: "active",
      label: this.p.t("USER_LIST.STATUS"),
      type: "select",
      options: [
        { label: this.p.t("USER_LIST.ALL"), value: "all" },
        { label: this.p.t("USER_LIST.ACTIVE"), value: "true" },
        { label: this.p.t("USER_LIST.INACTIVE"), value: "false" }
      ]
    }
  ];

  headers = [
    {
      name: this.p.t("USER_LIST.NAME"),
      key: "firstName"
    },
    {
      name: this.p.t("USER_LIST.SURNAME"),
      key: "lastName",
      sort: true
    },
    {
      name: this.p.t("USER_LIST.USERNAME"),
      key: "username"
    },
    {
      name: this.p.t("USER_LIST.EMAIL"),
      key: "email"
    },
    {
      name: this.p.t("USER_LIST.PHONE_NUMBER"),
      key: "phone"
    },
    {
      name: this.p.t("USER_LIST.STATUS"),
      key: "active",
      sort: true
    }
  ];

  getUserList = (sortParams: any) => {
    if (Object.keys(sortParams).length) {
      this.setState({ sortParams });
    }
    const sort = { ...this.state.sortParams, ...sortParams };
    const params = {
      ...sort,
      ...this.state.filters
    };
    if (params.active === "all") {
      const { active, ...data } = params;
      this.p.userStore.getUserListAction(data);
    } else {
      this.p.userStore.getUserListAction(params);
    }
  };

  handleAddUser = () => {
    this.p.history.push(`/users/add`);
  };

  handleOpenUser = (user: User) => () => {
    this.p.history.push(`/users/details/${user.id}`);
  };

  handleChange = (filters: any) => {
    this.setState({ filters }, () => this.getUserList({}));
  };

  render() {
    const {
      classes,
      t,
      userStore: { userList, usersTotalCount },
      appStore: { isFilter }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography
          className={classNames(classes.dividerFullWidth, "title")}
          color="textSecondary"
          display="block"
          variant="subtitle1"
        >
          {t("USER_LIST.TITLE")}
        </Typography>
        <Divider component="div" className={"divider"} />
        <Filters handleFilter={this.handleChange} filters={this.filtersList} />
        <List
          data={userList}
          getData={this.getUserList}
          headers={this.headers}
          isFilter={isFilter}
          totalCount={usersTotalCount}
          headerClass={classes.headerPadding}
        >
          {userList &&
            userList.length &&
            userList.map((item: any, i: number) => (
              <TableRow
                key={item.uuid || item.id || i}
                className={"rowList"}
                onClick={this.handleOpenUser(item)}
              >
                {this.headers.map((header: Header) => (
                  <TableCell key={header.key} className={classes.tableCell}>
                    {header.key === "active" ? (
                      <>
                        {!item[header.key] ? (
                          <InactiveIcon className={classes.inactiveColor} />
                        ) : (
                          <ActiveIcon className={classes.activeColor} />
                        )}
                      </>
                    ) : (
                      <>{item[header.key]}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </List>
        <Tooltip title="Add" placement="top-start">
          <Fab
            color="primary"
            aria-label="Add"
            className={classNames(classes.fab, classes.addButton)}
            onClick={this.handleAddUser}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: "100%",
      position: "relative",
      height: "calc(100vh - 90px)"
    },
    addButton: {
      color: "#fff",
      position: "fixed",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: 40
    },
    inactiveColor: {
      color: COLORS.ERROR
    },

    activeColor: {
      color: COLORS.SUCCESS
    },

    headerPadding: {
      padding: "10px 20px 10px 10px;",
      textAlign: "left"
    }
  });

export default withStyles(styles)(withTranslation()(UsersList));
