import { toJS } from "mobx";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../config/api";
import { stores } from "../App";
import REST from "../constants/rest";
import { FetchItOptions } from "../models/apiModels";
import { sentryErrorSend } from "../utils/sentryError";

axios.defaults.withCredentials = true;

async function fetchIt(url: string, options: FetchItOptions) {
  const request: AxiosRequestConfig = {
    method: options.method,
    url: API_URL + url,
    data: options.body
  };
  if (toJS(stores.authStore.session) && request.method !== REST.GET) {
    request.headers = {
      "Content-Type": "application/json",
      "X-Xsrftoken": toJS(stores.authStore.session).xsrfToken
    };
  } else {
    request.headers = {
      "Content-Type": "application/json"
    };
  }
  let response;

  try {
    response = await axios(request);

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        stores.authStore.logoutAction();
        stores.accountStore.clearAccountAction();
      } else if (error.response.status >= 400) {
        sentryErrorSend(request, error);
      }
      return { error: error.response.data, status: error.response.status };
    }
    return { error: "error" };
  }
}

async function sendFile(url: string, options: FetchItOptions) {
  const request: AxiosRequestConfig = {};
  const formData = new FormData();
  formData.append("file", options.body);
  if (toJS(stores.authStore.session) && request.method !== REST.GET) {
    request.headers = {
      "X-Xsrftoken": toJS(stores.authStore.session).xsrfToken,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    };
  }
  let response;

  try {
    response = await axios.post(API_URL + url, formData, request);

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        stores.authStore.logoutAction();
        stores.accountStore.clearAccountAction();
      }
      return { error: error.response.data, status: error.response.status };
    }
    return { error: "error" };
  }
}

async function fetchAll(items: any) {
  const requestsArray = items.map((item: any) => {
    const request: AxiosRequestConfig = {
      method: item.method,
      url: API_URL + item.path
    };
    if (item.body) {
      request.data = item.body;
    }
    if (toJS(stores.authStore.session) && request.method !== REST.GET) {
      request.headers = {
        "Content-Type": "application/json",
        "X-Xsrftoken": toJS(stores.authStore.session).xsrfToken
      };
    } else {
      request.headers = {
        "Content-Type": "application/json"
      };
    }
    return axios(request);
  });

  // try {
  //   const responses = await axios.all(requestsArray);
  //   responses.map(response => {
  //     if (response.status === 401) {
  //       stores.authStore.logoutAction();
  //     }
  //     if (response.status >= 400) {
  //       throw new Error(response.status);
  //     }
  //     return null;
  //   });
  //   return responses;
  // } catch (error) {
  //   if (error.response) {
  //     if (error.response.status === 401) {
  //       stores.authStore.logoutAction();
  //     } else if (error.response.status >= 400) {
  //       sentryErrorSend(requestsArray, error);
  //     }
  //     return { error: error.response.data, status: error.response.status };
  //   }
  //   return { error };
  // }
}

export default {
  sendFile: sendFile,
  fetch: fetchIt,
  fetchAll: fetchAll
};
