import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { OutlinedInput } from "@material-ui/core";
import { COLORS } from "../styles/colors";
import { Field } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(() =>
  createStyles({
    customSelect: {
      height: 56
    },
    select: {
      width: "100%",
      maxWidth: 380,
      height: 56,
      margin: 8,
      marginTop: 12,
      borderRadius: 5,
      borderColor: COLORS.WHITE,
      background: "rgba(255, 255, 255, 0.12)"
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    }
  })
);

interface CustomSelectProps {
  value: any;
  onChange: Function;
  disabled?: boolean;
  id: string;
  name: string;
  label: string;
  labelWidth?: number;
  error?: string;
  touched?: boolean;
}
const defaultLabelWidth = 60;
const CustomSelect: FunctionComponent<CustomSelectProps> = props => {
  const classes = useStyles();
  const {
    value,
    onChange,
    disabled,
    id,
    name,
    label,
    labelWidth,
    error,
    touched
  } = props;

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    onChange(event.target.value);
  }

  return (
    <FormControl
      variant="outlined"
      component={"div"}
      disabled={disabled}
      className={classes.select}
      error={Boolean(error && error.length && touched)}
    >
      <InputLabel>{label}</InputLabel>
      <Field
        id={id}
        name={name}
        render={() => (
          <Select
            value={value}
            onChange={handleChange}
            className={classes.customSelect}
            input={
              <OutlinedInput
                classes={{ notchedOutline: classes.notchedOutline }}
                labelWidth={labelWidth || defaultLabelWidth}
              />
            }
          >
            {props.children}
          </Select>
        )}
        variant="outlined"
      />
      {error && error.length && touched && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
