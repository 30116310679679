import REST from "../constants/rest";
import API from "./apiService";

export default class LocationService {
  static async getLocations(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/locations${params ? "?" + urlSP.toString() : ""}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getLocationById(id: string) {
    const path = `/locations/${id}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addLocation(data: any) {
    const path = "/locations";
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editLocation(params: any, id: string) {
    const path = `/locations/${id}`;
    const body = {
      name: params.name,
      active: params.active
    };
    const options = {
      method: REST.POST,
      body
    };
    return API.fetch(path, options);
  }

  static async deleteLocation(id: string) {
    const path = `/locations/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }
}
