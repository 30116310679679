import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

interface DialogProps {
  title: string;
  t: any;
  classes: any;
  submitButtonTitle: string;
  cancel: string;
  pending?: boolean;
  open: boolean;
  handleClickCloseDialog: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DialogComponent: React.FC<DialogProps> = ({
  title,
  t,
  classes,
  submitButtonTitle,
  cancel,
  pending,
  handleClickCloseDialog,
  handleDelete,
  open
}) => (
  <Dialog open={open} onClose={handleClickCloseDialog}>
    <DialogTitle id="alert-dialog-title">{t(`${title}`)}</DialogTitle>
    <DialogActions>
      <Button
        className={classes.btnColor}
        onClick={handleDelete}
        color="primary"
        disabled={pending}
        data-cy="delete"
      >
        {pending ? (
          <CircularProgress size={20} thickness={5} color="primary" />
        ) : (
          <div>{t(`${submitButtonTitle}`)}</div>
        )}
      </Button>
      <Button
        className={classes.btnColor}
        onClick={handleClickCloseDialog}
        color="primary"
        autoFocus
        data-cy="cancel"
      >
        {t(`${cancel}`)}
      </Button>
    </DialogActions>
  </Dialog>
);
