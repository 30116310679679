import REST from "../constants/rest";
import API from "./apiService";

export default class AdminService {
  static async getAdmins(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/users?type=ADMIN${
      params && Object.keys(params).length ? "&" + urlSP.toString() : ""
    }`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getAdminById(id: string) {
    const path = `/users/${id}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addAdmin(data: any) {
    const path = "/users";
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editAdmin(params: any, id: string) {
    const path = `/users/${id}`;
    const options = {
      method: REST.POST,
      body: params
    };
    return API.fetch(path, options);
  }

  static async deleteAdmin(id: string) {
    const path = `/users/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }

  static async getFile(params: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/sizes/tasks?include=users${
      params ? "&" + urlSP.toString() : ""
    }`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async uploadFile(files: any) {
    const path = `/sizes/tasks`;
    const options = {
      method: REST.POST,
      body: files
    };
    return API.sendFile(path, options);
  }
}
