import REST from "../constants/rest";
import API from "./apiService";
import { UserCredentials } from "../stores/models/authStore.model";

export default class AccountService {
  static async login(params: UserCredentials) {
    const path = "/sessions";
    const body = {
      username: params.email,
      password: params.password
    };
    const options = {
      method: REST.POST,
      body
    };
    return API.fetch(path, options);
  }

  static async logout() {
    const path = "/sessions";
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }

  static async register(params: UserCredentials) {
    const path = "/register";
    const body = {
      email: params.email,
      password: params.password
    };
    const options = {
      method: REST.POST,
      body
    };
    return API.fetch(path, options);
  }

  static async getAccount() {
    const path = "/users/profile";
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async deleteAccount() {
    const path = "/users/profile";
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }

  static async updateAccount(data: any) {
    const path = "/users/profile";
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }
  static async forgotPassword(params: any) {
    const path = "/users/password";
    const body = {
      email: params.email
    };
    const options = {
      method: REST.POST,
      body
    };
    return API.fetch(path, options);
  }

  static async resetPassword(params: any) {
    const path = `/users/password`;
    const body = {
      password: params.password,
      token: params.token
    };
    const options = {
      method: REST.PUT,
      body
    };
    return API.fetch(path, options);
  }

  static async changePassword(params: any) {
    const path = "/users/password";
    const body = {
      password: params.password,
      newPassword: params.newPassword
    };
    const options = {
      method: REST.POST,
      body
    };
    return API.fetch(path, options);
  }
}
