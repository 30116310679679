import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import createStyles from "@material-ui/core/styles/createStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { RouteComponentProps } from "react-router";
import classNames from "classnames";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export type Header = {
  name: string;
  key: string;
  sort?: boolean;
};

type OrderDirection = "asc" | "desc";

interface MatchParams {}

interface ListProps extends WithTranslation {
  headers: Header[];
  onRowClick?: Function;
  getData: Function;
  headerClass?: string;
  data: any;
  totalCount?: number;
  isFilter?: boolean;
}

interface InjectedProps extends ListProps, RouteComponentProps<MatchParams> {
  classes: any;
}

interface ListState {
  page: number;
  tmpPage: number;
  limit: number;
  sort?: string;
  order?: OrderDirection;
}

class List extends Component<ListProps, ListState> {
  state: ListState = {
    page: 0,
    limit: 25,
    tmpPage: 0,
    order: "desc"
  };
  get p() {
    return this.props as InjectedProps;
  }

  componentDidMount(): void {
    this.p.getData(this.state);
  }

  handleRowClick = (item: any) => () => {
    this.p.onRowClick && this.p.onRowClick(item);
  };

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    this.p.getData({
      ...this.state,
      page: newPage
    });
    this.setState(() => ({
      page: newPage,
      tmpPage: 0
    }));
  };

  handleChangeFilterPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newFilterPage: number) => {
    this.p.getData({
      ...this.state,
      page: newFilterPage
    });
    this.setState(() => ({
      tmpPage: newFilterPage
    }));
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.p.getData({
      ...this.state,
      limit: parseInt(event.target.value, 10),
      page:
        Math.round(
          (this.state.page * this.state.limit) /
            parseInt(event.target.value, 10)
        ) >= 0
          ? Math.round(
              (this.state.page * this.state.limit) /
                parseInt(event.target.value, 10)
            )
          : 0
    });
    this.setState(prevState => ({
      limit: parseInt(event.target.value, 10),
      page:
        Math.round(
          (prevState.page * prevState.limit) / parseInt(event.target.value, 10)
        ) >= 0
          ? Math.round(
              (prevState.page * prevState.limit) /
                parseInt(event.target.value, 10)
            )
          : 0
    }));
  };

  createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    this.p.getData({
      ...this.state,
      sort: property,
      order: this.state.order === "desc" ? "asc" : "desc"
    });
    this.setState(prevState => ({
      sort: property,
      order: prevState.order === "desc" ? "asc" : "desc"
    }));
  };

  render() {
    const { headers, data, totalCount, classes, t, headerClass } = this.p;
    const { sort, order, page, limit, tmpPage } = this.state;
    return (
      <>
        <div className={classes.listContainer}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow className={classNames(classes.tableHeader)}>
                {headers.map((header: Header) => (
                  <TableCell
                    className={classNames(headerClass, classes.theadList)}
                    sortDirection={order}
                    variant="head"
                    key={header.key}
                  >
                    {header.sort ? (
                      <TableSortLabel
                        active={sort === header.key}
                        direction={order}
                        onClick={this.createSortHandler(header.key)}
                      >
                        {header.name}
                      </TableSortLabel>
                    ) : (
                      <>{header.name}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.children ? (
                this.props.children
              ) : (
                <>
                  {data && data.length ? (
                    data.map((item: any, i: number) => (
                      <TableRow
                        key={item.uuid || item.id || i}
                        className={"rowList"}
                        onClick={this.handleRowClick(item)}
                      >
                        {headers.map((header: Header) => (
                          <TableCell
                            key={header.key}
                            className={classes.tableCell}
                            style={{ fontFamily: "RBNExtraLight" }}
                          >
                            <>{item[header.key]}</>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        className={classes.noItems}
                        colSpan={headers.length}
                      >
                        {t("NO_ITEMS")}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
            <TableFooter />
          </Table>
        </div>
        <div className={classes.pagination}>
          {data && data.length && (
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={totalCount || 100}
              rowsPerPage={limit}
              page={this.p.isFilter ? tmpPage : page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={
                this.p.isFilter
                  ? this.handleChangeFilterPage
                  : this.handleChangePage
              }
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    noItems: {
      textAlign: "center"
    },

    listContainer: {
      width: "100%",
      overflowX: "auto",
      maxHeight: "calc(100% - 200px)"
    },

    pagination: {
      paddingBottom: 70
    },

    theadList: {
      zIndex: 100,
      padding: "0px 40px 0px 10px",
      fontSize: 14
    }
  });

export default withStyles(styles)(withTranslation()(List));
