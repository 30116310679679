import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import { COLORS } from "../styles/colors";
import classNames from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText";
import { toJS } from "mobx";

interface Option {
  label: string;
  value: string;
}

interface RenderOptionsProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  value: any;
  option: Option;
  formClass: any;
  inputClass: any;
  notchedDisabled: boolean;
}
interface Suggestion {
  label: string;
  value: any;
}
interface RenderSuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  selectedItem: Suggestion;
  suggestion: Suggestion;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    ((selectedItem && selectedItem.value) || "").indexOf(suggestion.value) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

const Autocomplete: FunctionComponent<any> = (props: any) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState<Option>({
    label: "",
    value: ""
  });
  const [initialized, setInitialized] = React.useState(false);
  const {
    options,
    disabled,
    initialValue,
    onValueChange,
    onInputChange,
    label,
    error,
    touched,
    reset,
    formClass,
    inputClass,
    notchedDisabled
  } = props;
  const handleChange = (selectedItem: Option) => {
    setSelectedItem(selectedItem);
    onValueChange(selectedItem && selectedItem.value);
  };

  if (
    !initialized &&
    selectedItem &&
    !selectedItem.value.length &&
    initialValue &&
    initialValue.value
  ) {
    setSelectedItem(initialValue);
    setInitialized(true);
  }

  if (selectedItem && selectedItem.value && reset) {
    setSelectedItem({ label: "", value: "" });
  }
  const inputClasses = notchedDisabled
    ? {}
    : {
        classes: {
          notchedOutline: classes.notchedOutline
        }
      };

  return (
    <FormControl
      component="div"
      className={formClass || classes.formControl}
      error={Boolean(error && touched)}
    >
      <Downshift
        id="downshift-simple"
        selectedItem={selectedItem}
        onChange={selectedItem => handleChange(selectedItem)}
        itemToString={item => (item ? item.label : "")}
        onInputValueChange={(inputValue: string) => onInputChange(inputValue)}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: label
          });
          return (
            <div>
              <TextField
                {...getInputProps()}
                autoComplete="off"
                disabled={disabled}
                id="autocomplete"
                error={Boolean(error && touched)}
                label={label}
                onClick={() => {
                  if (!disabled) {
                    onInputChange("");
                    openMenu();
                  }
                }}
                className={inputClass || classes.textField}
                margin="normal"
                onBlur={props.onBlur}
                variant="outlined"
                InputProps={inputClasses}
              />
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {options.map((suggestion: Option, index: number) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          );
        }}
      </Downshift>
      {error && error.length && touched && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

const AutocompleteMultiple: FunctionComponent<any> = (props: any) => {
  const classes = useStyles();
  const {
    options,
    disabled,
    initialValue,
    onValueChange,
    onInputChange,
    label,
    error,
    touched,
    handleFocus,
    formClass,
    inputClass,
    notchedDisabled
  } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState<Array<Option>>([]);
  const [initialized, setInitialized] = React.useState(false);

  const inputClasses = notchedDisabled
    ? {}
    : {
        classes: {
          notchedOutline: classes.notchedOutline
        }
      };

  if (
    !initialized &&
    selectedItem &&
    !selectedItem.length &&
    initialValue &&
    initialValue.length
  ) {
    setSelectedItem(initialValue);
    setInitialized(true);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }

  function handleInputChange(event: React.ChangeEvent<{ value: string }>) {
    setInputValue(event.target.value);
    onInputChange(event.target.value);
  }

  function handleChange(item: Option) {
    if (item) {
      let newSelectedItem = [...selectedItem];
      if (
        newSelectedItem.findIndex(
          (selected: Option) => selected.value === item.value
        ) === -1
      ) {
        newSelectedItem = [...newSelectedItem, item];
      }
      setInputValue("");
      setSelectedItem(newSelectedItem);
      onValueChange(newSelectedItem.map((item: Option) => item.value));
    }
  }

  const handleDelete = (item: Option) => () => {
    if (disabled) {
      return;
    }
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(
      newSelectedItem.findIndex(
        (selected: Option) => selected.value === item.value
      ),
      1
    );
    setSelectedItem(newSelectedItem);
    onValueChange(newSelectedItem.map((item: Option) => item.value));
  };
  return (
    <FormControl
      component="div"
      className={formClass || classes.formControl}
      error={Boolean(error && touched)}
    >
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          isOpen,
          openMenu,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex
        }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder: label
          });
          return (
            <div>
              <TextField
                {...getInputProps()}
                autoComplete="off"
                disabled={disabled}
                id="autocomplete"
                label={label}
                className={inputClass || classes.textField}
                margin="normal"
                onClick={() => {
                  if (!disabled) {
                    onInputChange("");
                    openMenu();
                  }
                }}
                error={Boolean(error && touched)}
                variant="outlined"
                InputProps={{
                  ...inputClasses,
                  className: classNames({
                    [classes.withChips]: true,
                    [classes.pt8]: Boolean(selectedItem.length)
                  }),
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      component="div"
                      key={item.value}
                      tabIndex={-1}
                      label={item.label}
                      className={classes.chip}
                      onDelete={disabled ? undefined : handleDelete(item)}
                    />
                  ))
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event);
                  onChange!(event as React.ChangeEvent<HTMLInputElement>);
                }}
              />
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {options.map((suggestion: Option, index: number) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem2
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          );
        }}
      </Downshift>
      {error && error.length && touched && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "100%",
      background: "rgba(255, 255, 255, 0.12)"
    },
    paper: {
      position: "relative",
      zIndex: 1000,
      marginTop: 0,
      left: 0,
      right: 0,
      height: "auto",
      maxHeight: 450,
      overflowY: "auto"
    },
    withChips: {
      display: "flex",
      maxWidth: 380,
      height: "auto",
      flexWrap: "wrap"
    },
    pt8: {
      paddingTop: 8
    },
    chip: {
      margin: theme.spacing(0.5, 0.25)
    },
    divider: {
      height: theme.spacing(2)
    },
    formControl: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      maxWidth: 380,
      overflowX: "visible"
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    }
  })
);

export { Autocomplete, AutocompleteMultiple };
