import React, { Component } from "react";
import "./App.scss";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  InjectedNotistackProps,
  SnackbarProvider,
  withSnackbar
} from "notistack";
import * as Sentry from "@sentry/browser";
import AppStore from "./stores/appStore";
import AccountStore from "./stores/accountStore";
import { COLORS } from "./styles/colors";
import AppRouter from "./components/AppRouter";
import AuthStore from "./stores/authStore";
import AdminStore from "./stores/adminStore";
import ProjectStore from "./stores/projectStore";
import UserStore from "./stores/userStore";
import EndUserStore from "./stores/endUserStore";
import GroupStore from "./stores/groupStore";
import LocationStore from "./stores/locationStore";
import MeasurementStore from "./stores/measurementStore";

const appStore = new AppStore();
const authStore = new AuthStore();
const accountStore = new AccountStore();
const userStore = new UserStore();
const adminStore = new AdminStore();
const projectStore = new ProjectStore();
const endUserStore = new EndUserStore();
const groupStore = new GroupStore();
const locationStore = new LocationStore();
const measurementStore = new MeasurementStore();
export const stores = {
  appStore,
  authStore,
  userStore,
  accountStore,
  adminStore,
  projectStore,
  endUserStore,
  groupStore,
  locationStore,
  measurementStore
};

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: COLORS.PRIMARY_DARK
    },
    secondary: {
      main: COLORS.PRIMARY
    },
    error: {
      main: COLORS.ERROR
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        fontFamily: '"RbFontLight",serif !important',
        textTransform: "uppercase"
      },
      text: {
        fontFamily: '"RbFontLight",serif !important',
        textTransform: "uppercase"
      },
      textPrimary: {
        color: COLORS.WHITE,
        "&:hover": {
          backgroundColor: COLORS.PRIMARY_DARK
        }
      }
    },
    MuiButtonBase: {
      root: {
        padding: 0
      }
    },

    MuiIconButton: {
      root: {
        padding: 0
      }
    },
    MuiTableCell: {
      root: {
        textAlign: "left",
        padding: "5px !important"
      }
    }
  }
});

interface AppProps extends InjectedNotistackProps {}

class App extends Component<AppProps, {}> {
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope: any) => {
      scope.setExtras(errorInfo);
    });
  }
  render() {
    return (
      <Provider {...stores}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </SnackbarProvider>
        </MuiThemeProvider>
      </Provider>
    );
  }
}
export default withSnackbar(App);
