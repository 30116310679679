import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { COLORS } from "../styles/colors";
import { withTranslation, WithTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { MenuItem } from "@material-ui/core";
import SelectFilter from "./SelectFilter";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { Autocomplete } from "./AutocompleteNew";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import AppStore from "../stores/appStore";
import { inject, observer } from "mobx-react";
import "moment/locale/en-gb";

// @ts-ignore
moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

export type FiltersList = {
  name: string;
  label: string;
  type: string;
  value?: string;
  options?: any;
  getDataFunction?: Function;
};

const styles = createStyles({
  customSelect: {
    height: 56
  },
  select: {
    width: "100%",
    maxWidth: 200,
    height: 56,
    margin: 8,
    marginTop: 12,
    borderRadius: 5,
    borderColor: COLORS.WHITE,
    background: "rgba(255, 255, 255, 0.12)"
  },
  notchedOutline: {
    borderWidth: 1,
    borderColor: COLORS.WHITE + "!important"
  },

  filterMargin: {
    marginRight: 20,
    marginLeft: 0,
    "@media (max-width:500px)": {
      marginRight: 0,
      maxWidth: 300
    }
  },
  clearFilters: {
    height: 56,
    width: 100,
    background: COLORS.PRIMARY_DARK,
    marginTop: 12
  },

  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "@media (max-width:500px)": {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  textField: {
    width: "100%",
    maxWidth: 200,
    background: "rgba(255, 255, 255, 0.12)",
    "@media (max-width:500px)": {
      maxWidth: 300
    }
  },

  formControl: {
    marginRight: 20,
    width: "100%",
    maxWidth: 200,
    marginTop: -4,
    overflowX: "visible",
    "@media (max-width:500px)": {
      maxWidth: 300,
      marginRight: 0
    }
  }
});

interface FiltersProps extends WithTranslation {
  handleFilter?: any;
  filters: any;
}

interface InjectedProps extends FiltersProps {
  classes: any;
  appStore: AppStore;
}

interface FiltersState {
  filters: {
    [key: string]: string;
  };
}

@inject("appStore")
@observer
class Filters extends Component<FiltersProps, FiltersState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    filters: this.getInitialFilters()
  };

  componentDidMount(): void {}

  getInitialFilters() {
    let result: { [key: string]: any } = {};
    this.p.filters.forEach((filter: FiltersList) => {
      if (filter.type === "select") {
        result[filter.name] = filter.options[0].value;
      } else if (filter.type === "datepicker") {
        result[filter.name] = null;
      } else {
        result[filter.name] = "";
      }
    });
    return { ...result };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.p.appStore.filterStart();
    const { name, value } = event.target;
    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value
        }
      }),
      () => this.p.handleFilter(this.mapFiltersToApi(this.state.filters))
    );
  };

  handleAutocompleteChange = (name: string) => (value: string) => {
    this.p.appStore.filterStart();
    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value
        }
      }),
      () => this.p.handleFilter(this.mapFiltersToApi(this.state.filters))
    );
  };

  handleDateChange = (name: string) => (value: any) => {
    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [name]: moment(value)
            .locale("en")
            .format("DD-MM-YYYY")
        }
      }),
      () => this.p.handleFilter(this.mapFiltersToApi(this.state.filters))
    );
  };

  clearFilter = () => {
    this.setState(
      prevState => ({ filters: this.getInitialFilters() }),
      () => this.p.handleFilter(this.mapFiltersToApi(this.state.filters))
    );
    this.p.appStore.filterEnd();
  };

  mapFiltersToApi = (filters: { [key: string]: string }) => {
    let result: { [key: string]: string } = {};
    Object.keys(filters).forEach((key: string) => {
      if (filters[key] && filters[key] !== "") {
        result[key] = filters[key];
      }
    });
    return result;
  };

  render() {
    const { t, classes } = this.p;

    return (
      <div className={classes.filterContainer}>
        {this.p.filters.map(
          (filter: FiltersList) =>
            filter.type === "input" && (
              <TextField
                key={filter.name}
                id={filter.name}
                name={filter.name}
                value={this.state.filters[filter.name] || ""}
                label={filter.label}
                className={classNames(classes.filterMargin, classes.select)}
                onChange={this.handleChange}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
            )
        )}

        {this.p.filters.map(
          (filter: FiltersList) =>
            filter.type === "select" && (
              <SelectFilter
                key={filter.label}
                onChange={this.handleChange}
                value={this.state.filters["active"]}
                id={filter.name}
                name={filter.name}
                label={filter.label}
              >
                {filter.options &&
                  filter.options.map((option: any) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </SelectFilter>
            )
        )}

        {this.p.filters.map(
          (filter: FiltersList) =>
            filter.type === "autocomplete" && (
              <Autocomplete
                key={filter.label}
                label={filter.label}
                options={filter.options}
                onInputChange={filter.getDataFunction}
                inputClass={classes.textField}
                formClass={classes.formControl}
                onValueChange={this.handleAutocompleteChange(filter.name)}
                id={filter.name}
                name={filter.name}
                required
                notchedDisabled
                reset={!this.state.filters[filter.name]}
              />
            )
        )}

        {this.p.filters.map(
          (filter: FiltersList) =>
            filter.type === "datepicker" && (
              <MuiPickersUtilsProvider utils={MomentUtils} key={filter.label}>
                <DatePicker
                  className={classNames(classes.filterMargin, classes.select)}
                  label={filter.label}
                  format="DD-MM-YYYY"
                  inputVariant="outlined"
                  value={
                    this.state.filters[filter.name] &&
                    moment(this.state.filters[filter.name], "DD-MM-YYYY")
                  }
                  onChange={this.handleDateChange(filter.name)}
                />
              </MuiPickersUtilsProvider>
            )
        )}
        <Button className={classes.clearFilters} onClick={this.clearFilter}>
          {t("CLEAR")}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(Filters));
