import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { COLORS } from "../../styles/colors";
import { TextField } from "formik-material-ui";
import { Formik, FormikActions, Form, Field } from "formik";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import { StringValidator } from "../../utils/validators";
import ProjectStore, { Project } from "../../stores/projectStore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";
import { DialogComponent } from "../DialogComponent";
import CustomSelect from "../CustomSelectComponent";
import { AutocompleteMultiple } from "../AutocompleteNew";
import GroupStore, { Group, GroupLI } from "../../stores/groupStore";
import LocationStore, { Location } from "../../stores/locationStore";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { toJS } from "mobx";

interface MatchParams {
  id: string;
}

interface ProjectProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends ProjectProps, RouteComponentProps<MatchParams> {
  projectStore: ProjectStore;
  groupStore: GroupStore;
  locationStore: LocationStore;
  classes: any;
  projectId: string;
}

class ProjectForm {
  constructor(project?: Project) {
    if (project) {
      this.name = project.name;
      this.groups = project.groups.map(item => item.id);
      this.locations = project.locations.map(item => item.id);
      this.type = project.type || "VEST";
    }
  }
  name: string = "";
  groups: string[] = [];
  locations: string[] = [];
  type: string = "VEST";
}

const ProjectSchema = Yup.object().shape<ProjectForm>({
  name: StringValidator,
  groups: Yup.array()
    .of(Yup.string())
    .required(),
  locations: Yup.array()
    .of(Yup.string())
    .required(),
  type: StringValidator
});

interface ProjectState {
  edit: boolean;
  open: boolean;
  group: string[];
  locations: any;
}

@inject("projectStore", "groupStore", "locationStore")
@observer
class ProjectDetails extends Component<ProjectProps, ProjectState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    edit: !this.p.match.params.id,
    open: false,
    group: [],
    locations: {
      label: [],
      value: []
    }
  };

  componentDidMount(): void {
    this.getProjectDetails();
  }

  componentWillUnmount(): void {
    this.p.projectStore.resetProject();
  }

  submit = async (values: ProjectForm) => {
    if (this.p.match.params.id) {
      this.editProject(values);
    } else {
      this.addProject(values);
    }
  };

  getProjectDetails = async () => {
    if (this.p.match.params.id) {
      const success = await this.p.projectStore.getProjectByIdAction(
        this.p.match.params.id
      );
      if (!success) {
        this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_GET_PROJECT"), {
          variant: "error"
        });
      } else {
        let params: any = [];
        this.p.projectStore.project.groups.map((item: any) =>
          params.push(item.id)
        );
        this.p.groupStore.setCountGroup(params);
      }
    }
  };

  addProject = async (values: ProjectForm) => {
    const success = await this.p.projectStore.addProjectAction(values);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.CANT_ADD_PROJECT"), {
        variant: "error"
      });
    } else {
      this.p.enqueueSnackbar(this.p.t("PROJECT.ADD_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/projects");
    }
  };

  editProject = async (values: ProjectForm) => {
    const id = this.p.match.params.id;
    const success = await this.p.projectStore.editProjectAction(values, id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_EDIT_PROJECT"), {
        variant: "error"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("PROJECT.UPDATE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/projects");
    }
  };

  getGroupsOptions = async (search: string) => {
    await this.p.groupStore.getGroupOptionsAutocompleteAction({ name: search });
  };

  getLocationsOptions = async () => {
    // await this.p.groupStore.setCountGroup(this.state.group)
  };

  enableEdit = (
    resetForm: (values: any) => void,
    values: any,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    resetForm(values);
    this.setState({ edit: true });
  };

  disableEdit = () => {
    this.setState({ edit: false });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  deleteProject = async () => {
    const id = this.p.match.params.id;
    const success = await this.p.projectStore.deleteProjectAction(id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.CANT_DELETE_PROJECT"), {
        variant: "error"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("PROJECT.UPDATE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/projects");
    }
  };

  backToList = () => {
    this.p.history.goBack();
  };

  render() {
    const {
      classes,
      t,
      groupStore: { groupOptionsAutocomplete, locationList },
      locationStore: { locationOptionsAutocomplete },
      projectStore: { pending, project }
    } = this.p;
    const projectId = this.p.match.params.id;
    return (
      <Formik
        enableReinitialize
        initialValues={new ProjectForm(project)}
        validationSchema={ProjectSchema}
        onSubmit={(
          values: ProjectForm,
          actions: FormikActions<ProjectForm>
        ) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new ProjectForm();
        }}
      >
        {props => {
          const {
            values,
            setFieldValue,
            setFieldTouched,
            errors,
            touched
          } = props;
          return (
            <Form noValidate>
              <div className={"titleContainer"}>
                <BackIcon className={"backList"} onClick={this.backToList} />
                <Typography
                  className={classNames(classes.dividerFullWidth, "title")}
                  display="block"
                  variant="subtitle1"
                >
                  {t("PROJECT.TITLE")}
                </Typography>
              </div>
              <Divider component="div" className={"divider"} />
              <div className={classes.container}>
                <div className={classes.content}>
                  <Card
                    className={classNames(classes.card, classes.cardContainer)}
                  >
                    <CardContent className={classes.cardContent}>
                      <Field
                        id="name"
                        name="name"
                        disabled={!this.state.edit}
                        label={t("PROJECT.NAME")}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl
                          }
                        }}
                        component={TextField}
                        className={classes.textField}
                        margin="normal"
                        autoComplete="off"
                        variant="outlined"
                      />
                      <CustomSelect
                        id="type"
                        name="type"
                        disabled
                        value={values.type}
                        onChange={(val: any) => {
                          setFieldValue("type", val);
                          setFieldTouched("type");
                        }}
                        label={t("PROJECT.TYPE")}
                        error={errors.type}
                        touched={!!touched.type}
                        labelWidth={40}
                      >
                        <MenuItem value="VEST">
                          {t("PROJECT.TYPES.VEST")}
                        </MenuItem>
                      </CustomSelect>
                      <AutocompleteMultiple
                        error={errors.groups}
                        touched={touched.groups}
                        label={t("PROJECT.GROUPS")}
                        options={groupOptionsAutocomplete}
                        disabled={!this.state.edit}
                        onInputChange={this.getGroupsOptions}
                        onValueChange={(value: any) => {
                          setFieldTouched("groups");
                          setFieldValue("groups", value);
                          this.p.groupStore.setCountGroup(value);
                        }}
                        id="groups"
                        name="groups"
                        required
                        initialValue={project.groups.map((item: Group) => ({
                          label: item.name,
                          value: item.id
                        }))}
                      />
                      <AutocompleteMultiple
                        error={errors.locations}
                        touched={touched.locations}
                        label={t("PROJECT.LOCATIONS")}
                        options={locationList}
                        disabled={!this.state.edit}
                        onInputChange={this.getLocationsOptions}
                        onValueChange={(value: any) => {
                          setFieldTouched("locations");
                          setFieldValue("locations", value);
                        }}
                        id="locations"
                        name="locations"
                        required
                        initialValue={project.locations.map(
                          (item: Location) => ({
                            label: item.name,
                            value: item.id
                          })
                        )}
                      />
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                      {projectId && (
                        <>
                          {!this.state.edit ? (
                            <>
                              <Button
                                aria-label="Edit"
                                type="button"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                onClick={event =>
                                  this.enableEdit(
                                    props.resetForm,
                                    props.values,
                                    event
                                  )
                                }
                                disabled={pending}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("EDIT")
                                )}
                              </Button>
                              <Button
                                aria-label="Delete"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                onClick={this.handleClickOpenDialog}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("DELETE")
                                )}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                aria-label="Save"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                type="submit"
                                disabled={pending}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("SAVE")
                                )}
                              </Button>
                              <Button
                                aria-label="Delete"
                                className={classNames(
                                  classes.button,
                                  classes.btn
                                )}
                                onClick={this.disableEdit}
                              >
                                {pending ? (
                                  <CircularProgress
                                    size={20}
                                    thickness={5}
                                    color="primary"
                                  />
                                ) : (
                                  t("CANCEL")
                                )}
                              </Button>
                            </>
                          )}
                          <DialogComponent
                            title={"PROJECT.PROJECT_DELETE"}
                            t={t}
                            classes={classes}
                            submitButtonTitle={"DELETE"}
                            cancel={"CANCEL"}
                            pending={pending}
                            handleClickCloseDialog={this.handleCloseDialog}
                            handleDelete={this.deleteProject}
                            open={this.state.open}
                          />
                        </>
                      )}
                      {!this.p.match.params.id && (
                        <Button
                          aria-label="Save"
                          className={classNames(classes.fab, classes.btn)}
                          type="submit"
                        >
                          {pending ? (
                            <CircularProgress
                              size={20}
                              thickness={5}
                              color="primary"
                            />
                          ) : (
                            t("SAVE")
                          )}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      backgroundSize: "cover"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      maxWidth: 380,
      marginTop: 12
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    },

    formControl: {
      background: "rgba(255, 255, 255, 0.12)"
    },

    content: {
      width: "100%",
      padding: 20,

      "@media (max-width:1200px)": {
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }
    },

    cardContainer: {
      background: "transparent",
      display: "flex",
      flexDirection: "column",
      width: "80%",
      margin: "0 auto",
      boxShadow: "none",
      overflow: "visible"
    },

    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    select: {
      width: "80%",
      maxWidth: 380,
      height: 56,
      marginTop: 10,
      borderRadius: 5,
      borderColor: COLORS.WHITE
    },

    btn: {
      margin: "0 10px",
      width: "10%",
      background: COLORS.PRIMARY_DARK,
      color: COLORS.WHITE
    },

    cardAction: {
      justifyContent: "center"
    },

    btnColor: {
      color: COLORS.WHITE,
      "&:hover": {
        background: COLORS.PRIMARY_DARK
      }
    }
  });

export default withStyles(styles)(
  withTranslation()(withSnackbar(ProjectDetails))
);
