import { action, configure, observable } from "mobx";
import locationService from "../services/locationService";
configure({ enforceActions: "observed" });

interface LocationBase {
  name: string;
  active: boolean;
}

export interface Location extends LocationBase {
  id: string;
}

export default class LocationStore {
  // STORE
  @observable location: Location = {
    id: "",
    name: "",
    active: true
  };

  @observable pending: boolean = false;

  @observable locationList: Location[] = [];
  @observable locationOptionsAutocomplete: Location[] = [];

  @observable locationsTotalCount?: number;

  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.locationsTotalCount = totalCount;
  };

  // ACTIONS

  @action
  private setLocation = (location: Location) => {
    this.location = location;
  };

  @action
  private setLocationList = (locationList: Location[]) => {
    this.locationList = locationList;
  };

  @action
  private setLocationOptions = (locationList: Location[]) => {
    this.locationOptionsAutocomplete = locationList;
  };

  @action
  getLocationListAction = async (params?: any) => {
    this.pendingStart();
    const result = await locationService.getLocations(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }

    this.setLocationList(result.locations);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  getLocationOptionsAutocompleteAction = async (params?: any) => {
    this.pendingStart();
    const result = await locationService.getLocations(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }

    this.setLocationOptions(
      result.locations.map((item: Location) => ({
        label: item.name,
        value: item.id
      }))
    );
    return true;
  };

  @action
  addLocationAction = async (data: any) => {
    this.pendingStart();
    const result = await locationService.addLocation(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getLocationListAction();
    return true;
  };

  @action
  getLocationByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await locationService.getLocationById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setLocation(result.location);
    return true;
  };

  @action
  editLocationAction = async (params: LocationBase, id: string) => {
    this.pendingStart();
    const result = await locationService.editLocation({ ...params }, id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  deleteLocationAction = async (id: string) => {
    this.pendingStart();
    const result = await locationService.deleteLocation(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
}
