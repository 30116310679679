import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, Form, Formik, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import EndUserStore from "../../../stores/endUserStore";
import * as Yup from "yup";
import { StringValidator } from "../../../utils/validators";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../../styles/colors";
import CustomSelect from "../../CustomSelectComponent";
import { Autocomplete } from "../../AutocompleteNew";
import LocationStore from "../../../stores/locationStore";
import GroupStore from "../../../stores/groupStore";
import ProjectStore from "../../../stores/projectStore";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { toJS } from "mobx";
import {dataToUpperCase} from "../../../utils/dataToUppercase"

interface MatchParams {
  id: string;
}

interface UserStepProps extends WithTranslation, InjectedNotistackProps {
  handleNext: (values: UserStepForm, id: string) => void;
  values: UserStepForm;
  endUserDetailId?: any;
  endUserId?: string;
}

interface InjectedProps
  extends UserStepProps,
    RouteComponentProps<MatchParams> {
  groupStore: GroupStore;
  projectStore: ProjectStore;
  locationStore: LocationStore;
  endUserStore: EndUserStore;
  classes: any;
}

export class UserStepForm {
  constructor(values?: any) {
    if (values) {
      this.firstName = values.firstName;
      this.lastName = values.lastName;
      this.group = values.group.id;
      this.location = values.location.id;
      this.pid = values.pid;
      this.gender = values.gender;
      this.project = values.project.id;
    }
  }
  firstName: string = "";
  lastName: string = "";
  group: string = "";
  location: string = "";
  gender: string = "";
  project: string = "";
  pid: string = "";
}

const UserStepSchema = Yup.object().shape<UserStepForm>({
  firstName: StringValidator,
  pid: StringValidator,
  lastName: StringValidator,
  group: StringValidator,
  location: StringValidator,
  gender: StringValidator,
  project: StringValidator
});

interface UserStepState {
  endUser: any;
  groups: { label: string; value: any }[];
  locations: { label: string; value: any }[];
  refresh: boolean;
  findPID: boolean;
  checkPID: boolean;
  searchPID: any;
}

@inject("projectStore", "groupStore", "endUserStore", "locationStore")
@observer
class UserStep extends Component<UserStepProps, UserStepState> {
  state = {
    checkPID: false,
    searchPID: "",
    endUser: {
      id: "",
      firstName: "",
      lastName: "",
      pid: "",
      gender: "",
      group: {
        active: true,
        id: "",
        name: ""
      },
      location: {
        active: true,
        id: "",
        name: ""
      },
      project: {
        groups: [],
        id: "",
        locations: [],
        name: "",
        type: ""
      }
    },
    groups: [],
    locations: [],
    refresh: false,
    findPID: true
  };
  get p() {
    return this.props as InjectedProps;
  }

  submit = async (values: UserStepForm) => {
    let result;
    dataToUpperCase(values);
    if (this.state && this.state.endUser && this.state.searchPID === "") {
      result = await this.p.endUserStore.editEndUserAction(
        values,
        this.state.endUser.id
      );
    } else {
      result = await this.p.endUserStore.addEndUserAction(values);
    }

    if (result) {
      this.p.handleNext(values, result);
    }
  };

  componentDidMount(): void {
    this.getEndUserById();
    if (this.p.endUserDetailId) {
      this.getEndUserByIdRef();
    }
  }

  getEndUserById = async () => {
    if (this.p.endUserId) {
      const result = await this.p.endUserStore.getEndUserByIdAction(
        this.p.endUserId
      );
      if (result) {
        this.setState({ endUser: result });
      }
    }
  };

  getEndUserByIdRef = async () => {
    if (this.p.endUserDetailId) {
      const result = await this.p.endUserStore.getEndUserByIdAction(
        this.p.endUserDetailId
      );
      if (result) {
        this.setState({ endUser: result, findPID: false, checkPID:true });
        this.p.groupStore.setLocationGroup(
          this.p.endUserStore.endUser.group.id
        );
        this.setState({ locations: this.p.groupStore.locationList });
      }
    }
  };

  handlePID = async (pid: string, resetForm?: any) => {
    this.setState({ refresh: true });
    const result = await this.p.endUserStore.getEndUserByPID(pid);
    if (result) {
      this.getProject(result.project.id);
      resetForm(new UserStepForm(result));
      this.setState({ searchPID: '' });
      this.setState({ endUser: result });
      this.p.groupStore.setLocationGroup(result.group.id);
      this.setState({ locations: this.p.groupStore.locationList });
      this.props.enqueueSnackbar(this.p.t("END_USER.GET_DATA"), {
        variant: "success"
      });
    } else {
      this.props.enqueueSnackbar(this.p.t("END_USER.NOT_FOUND"), {
        variant: "info"
      });
      this.setState({
        findPID: false,
        searchPID: pid,
        endUser: {
          firstName: "",
          lastName: "",
          pid: pid,
          gender: "",
          group: {
            active: true,
            id: "",
            name: ""
          },
          location: {
            active: true,
            id: "",
            name: ""
          },
          project: {
            groups: [],
            id: "",
            locations: [],
            name: "",
            type: ""
          }
        }
      });
      resetForm(new UserStepForm(""));
    }
    this.setState({ refresh: false });
    this.setState({ checkPID: true });
  };

  setLocationChoose = (value: string) => {
    const location: any = [];
    const result = this.p.projectStore.project.groups.find(
      (item: any) => item.id === value
    );
    const groupLocation = toJS(result && result.locations);
    const projectLocation = toJS(
      this.p.projectStore.project.locations.map((item: any) => item)
    );
    projectLocation.map((item: any) => {
      if (
        groupLocation &&
        groupLocation.indexOf(item.id) !== -1 &&
        item.active === true
      ) {
        location.push({ label: item.name, value: item.id });
      }
    });
    this.setState({ locations: location });
  };

  getGroupsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    if (project) {
      this.setState({
        groups: project.groups
          .filter(
            (item: any) =>
              item.active &&
              item.name.toLowerCase().includes(search.toLowerCase())
          )
          .map((obj: any) => ({
            label: obj.name,
            value: obj.id
          }))
      });
    }
  };

  getLocationsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    // if(project){
    //     this.setState({locations:project.locations
    //           .filter((item: any) =>
    //             item.active && item.name.toLowerCase().includes(search.toLowerCase())
    //           ).map((obj:any)=> ({
    //             label: obj.name,
    //             value: obj.id
    //         }))})
    // }
  };

  getProjectsOptions = async (search: string) => {
    await this.p.projectStore.getProjectOptionsAutocompleteAction({
      name: search
    });
  };

  getProject = async (id: string) => {
    await this.p.projectStore.getProjectByIdAction(id);
  };

  render() {
    const {
      classes,
      t,
      projectStore: { projectOptionsAutocomplete }
    } = this.p;
    const isIE = window.navigator.userAgent.indexOf("Trident") !== -1;
    return (
      <div className={classes.stepContainer}>
        {!this.state.refresh && (
          <Formik
            enableReinitialize
            initialValues={new UserStepForm(this.state && this.state.endUser)}
            validationSchema={UserStepSchema}
            onSubmit={(
              values: UserStepForm,
              actions: FormikActions<UserStepForm>
            ) => {
              this.submit(values).then(() => {
                actions.setSubmitting(false);
              });
            }}
            onReset={values => {
              values = new UserStepForm();
            }}
          >
            {props => {
              const {
                isValid,
                resetForm,
                setFieldValue,
                setFieldTouched,
                values,
                errors,
                touched
              } = props;
              return (
                <Form noValidate className={classes.endUserInfo}>
                  <Field
                    id="pid"
                    name="pid"
                    label={t("MEASUREMENT.PID")}
                    required
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl,
                        input: classes.transformText
                      }
                    }}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isValid && values.pid === ""}
                    onClick={() =>
                      this.handlePID(values.pid.toUpperCase(), resetForm)
                    }
                    className={classes.button}
                  >
                    {t("CHECK")}
                  </Button>
                  <Field
                    id="firstName"
                    name="firstName"
                    label={t("MEASUREMENT.NAME")}
                    required
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl,
                        input: classes.transformText
                      }
                    }}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Field
                    id="lastName"
                    name="lastName"
                    label={t("MEASUREMENT.SURNAME")}
                    required
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl,
                        input: classes.transformText
                      }
                    }}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Autocomplete
                    error={errors.project}
                    touched={touched.project}
                    label={t("MEASUREMENT.PROJECT")}
                    onBlur={() => setFieldTouched("project")}
                    options={projectOptionsAutocomplete}
                    onInputChange={this.getProjectsOptions}
                    onValueChange={(value: any) => {
                      setFieldTouched("project");
                      setFieldValue("project", value);
                      setFieldValue("group", "-");
                      setFieldValue("location", "-");
                      this.getProject(value);
                    }}
                    id="project"
                    name="project"
                    required
                    value={values.project}
                    initialValue={{
                      label:
                        this.state &&
                        this.state.endUser &&
                        this.state.endUser.project.name,
                      value:
                        this.state &&
                        this.state.endUser &&
                        this.state.endUser.project.id
                    }}
                  />
                  <Autocomplete
                    error={errors.group}
                    onBlur={() => setFieldTouched("group")}
                    touched={touched.group}
                    label={t("MEASUREMENT.GROUP")}
                    options={this.state && this.state.groups}
                    disabled={!this.p.projectStore.project.id}
                    onInputChange={this.getGroupsOptions}
                    onValueChange={(value: any) => {
                      setFieldTouched("group");
                      setFieldValue("group", value);
                      this.setLocationChoose(value);
                    }}
                    id="group"
                    name="group"
                    required
                    reset={values.group === "-"}
                    initialValue={{
                      label:
                        this.state &&
                        this.state.endUser &&
                        this.state.endUser.group.name,
                      value:
                        this.state &&
                        this.state.endUser &&
                        this.state.endUser.group.id
                    }}
                  />
                  <Autocomplete
                    error={errors.location}
                    touched={touched.location}
                    label={t("MEASUREMENT.LOCATION")}
                    onBlur={() => setFieldTouched("location")}
                    options={this.state && this.state.locations}
                    disabled={!this.p.projectStore.project.id}
                    onInputChange={this.getLocationsOptions}
                    onValueChange={(value: any) => {
                      setFieldTouched("location");
                      setFieldValue("location", value);
                    }}
                    id="location"
                    name="location"
                    required
                    reset={values.location === "-"}
                    initialValue={{
                      label:
                        this.state &&
                        this.state.endUser &&
                        this.state.endUser.location.name,
                      value:
                        this.state &&
                        this.state.endUser &&
                        this.state.endUser.location.id
                    }}
                  />
                  <CustomSelect
                    id="gender"
                    name="gender"
                    value={values.gender}
                    onChange={(val: any) => {
                      setFieldValue("gender", val);
                      setFieldTouched("gender");
                    }}
                    label={t("MEASUREMENT.GENDER")}
                    error={errors.gender}
                  >
                    <MenuItem value="FEMALE">
                      {t("MEASUREMENT.FEMALE")}
                    </MenuItem>
                    <MenuItem value="MALE">{t("MEASUREMENT.MALE")}</MenuItem>
                  </CustomSelect>
                  <div
                    className={
                      !isIE ? classes.btnContainer : classes.btnContainerIe
                    }
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={(!isValid && values.gender === '') || !this.state.checkPID}
                        type="submit"
                        className={classes.button}
                      >
                        {t("Next")}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: "#fff",
      position: "absolute",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    transformText: {
      textTransform: "uppercase"
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    },

    formControl: {
      background: "rgba(255, 255, 255, 0.12)"
    },

    stepContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },

    textField: {
      width: "100%",
      maxWidth: 380
    },

    btnContainerIe: {
      width: "40%",
      bottom: 40,
      position: "fixed",
      textAlign: "right"
    },

    btnContainer: {
      textAlign: "right",
      width: "calc(100% - 372px)",
      position: "fixed",
      bottom: 40,
      "@media (max-width:1200px)": {
        padding: 20,
        position: "relative",
        bottom: 0,
        width: "100%"
      }
    },

    endUserInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      overflow: "visible"
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(UserStep)));
