import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Button, Typography } from "@material-ui/core";
import { Link, RouteComponentProps } from "react-router-dom";
import classNames from "classnames";
import AccountStore from "../../stores/accountStore";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import AuthStore from "../../stores/authStore";
import { TextField } from "formik-material-ui";
import Logo from "../../assets/images/sf_logo.svg";
import { Formik, FormikActions, Form, Field } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import { EmailValidator } from "../../utils/validators";
import styles from "./style";

interface MatchParams {}

interface ForgotPasswordProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps
  extends ForgotPasswordProps,
    RouteComponentProps<MatchParams> {
  authStore: AuthStore;
  accountStore: AccountStore;
  classes: any;
  location: any;
}

class ForgotPasswordForm {
  email: string = "";
}

const ForgotPasswordSchema = Yup.object().shape<ForgotPasswordForm>({
  email: EmailValidator
});

@inject("authStore", "accountStore")
@observer
class ForgotPassword extends Component<ForgotPasswordProps> {
  get p() {
    return this.props as InjectedProps;
  }

  submit = async (values: ForgotPasswordForm) => {
    const { t } = this.props;
    const success = await this.p.accountStore.forgotPasswordAction({
      email: values.email
    });
    if (!success) {
      this.props.enqueueSnackbar(this.p.t("ERRORS.EMAIL_NOT_VALID"), {
        variant: "error"
      });
    } else {
      this.p.history.push("/login");
      this.p.enqueueSnackbar(t("FORGOT_PASSWORD.SEND_MAIL"), {
        variant: "success"
      });
    }
  };

  render() {
    const {
      classes,
      t,
      authStore: { pending }
    } = this.p;

    return (
      <Formik
        initialValues={new ForgotPasswordForm()}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(
          values: ForgotPasswordForm,
          actions: FormikActions<ForgotPasswordForm>
        ) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {props => {
          const { isSubmitting, isValid } = props;
          return (
            <div className={classes.container}>
              <div className={classes.logo}>
                <img src={Logo} alt="Logo" />
              </div>
              <div className={classes.loginContainer}>
                <div className={classes.logoContainer}>
                  <Typography variant="h1" className={classes.title}>
                    SizeFit 3
                  </Typography>
                  <Typography variant="h2" className={classes.subtitle}>
                    Admin
                  </Typography>
                </div>
                <div className={classes.content}>
                  <Form className={classes.center}>
                    <div className={classes.spacing}></div>
                    <Field
                      id="email"
                      name="email"
                      label={t("LABELS.EMAIL")}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.control
                        }
                      }}
                      component={TextField}
                      className={classes.textField}
                      margin="normal"
                      autoComplete="off"
                      variant="outlined"
                    />
                    <div className={classes.forgotContainer}>
                      <Link to="/login">{t("LABELS.LOGIN")}</Link>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid}
                      className={classNames(
                        classes.button,
                        classes.buttonWidth
                      )}
                      type="submit"
                      data-cy="passwordReminder"
                    >
                      {pending ? (
                        <CircularProgress
                          size={20}
                          thickness={5}
                          color="secondary"
                        />
                      ) : (
                        <div>{t("LABELS.REMIND_PASSWORD")}</div>
                      )}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(withSnackbar(ForgotPassword))
);
