import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../styles/colors";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { RouteComponentProps } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import ProjectStore, { Project } from "../../stores/projectStore";
import List, { Header } from "../List";
import { inject, observer } from "mobx-react";
import Filters from "../Filters";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import AppStore from "../../stores/appStore";

interface MatchParams {
  history: string;
}

interface ProjectListProps extends WithTranslation {}

interface InjectedProps
  extends ProjectListProps,
    RouteComponentProps<MatchParams> {
  projectStore: ProjectStore;
  appStore: AppStore;
  classes: any;
}

interface ProjectsListState {
  filters: {
    [key: string]: string;
  };
  sortParams: {
    [key: string]: string;
  };
}

@inject("projectStore", "appStore")
@observer
class ProjectsList extends Component<ProjectListProps, ProjectsListState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    filters: {},
    sortParams: {}
  };

  filtersList = [
    {
      name: "name",
      label: this.p.t("PROJECT_LIST.NAME"),
      type: "input"
    }
  ];

  headers = [
    {
      name: this.p.t("PROJECT_LIST.NAME"),
      key: "name",
      sort: true
    },
    {
      name: this.p.t("PROJECT_LIST.TYPE"),
      key: "type",
      sort: true
    },
    {
      name: this.p.t("PROJECT_LIST.GROUPS"),
      key: "groups"
    },
    {
      name: this.p.t("PROJECT_LIST.LOCATIONS"),
      key: "locations"
    }
  ];

  getProjectList = (sortParams: any) => {
    if (Object.keys(sortParams).length) {
      this.setState({ sortParams });
    }
    const sort = { ...this.state.sortParams, ...sortParams };
    const params = {
      ...sort,
      ...this.state.filters
    };
    if (params.active === "all") {
      const { active, ...data } = params;
      this.p.projectStore.getProjectListAction(data);
    } else {
      this.p.projectStore.getProjectListAction(params);
    }
  };

  handleAddProject = () => {
    this.p.history.push(`/projects/add`);
  };

  handleOpenProject = (project: Project) => () => {
    this.p.history.push(`/projects/details/${project.id}`);
  };

  handleChange = (filters: any) => {
    this.setState({ filters }, () => this.getProjectList({}));
  };

  render() {
    const {
      classes,
      t,
      projectStore: { projectList, projectsTotalCount },
      appStore: { isFilter }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography
          className={classNames(classes.dividerFullWidth, "title")}
          color="textSecondary"
          display="block"
          variant="subtitle1"
        >
          {t("PROJECT_LIST.TITLE")}
        </Typography>
        <Divider component="div" className={"divider"} />
        <Filters handleFilter={this.handleChange} filters={this.filtersList} />
        <List
          data={projectList}
          getData={this.getProjectList}
          headers={this.headers}
          isFilter={isFilter}
          totalCount={projectsTotalCount}
          headerClass={classes.headerPadding}
        >
          {projectList &&
            projectList.length &&
            projectList.map((item: any, i: number) => (
              <TableRow
                key={item.uuid || item.id || i}
                className={"rowList"}
                onClick={this.handleOpenProject(item)}
              >
                {this.headers.map((header: Header) => (
                  <TableCell key={header.key} className={classes.tableCell}>
                    {header.key === "groups" || header.key === "locations" ? (
                      <>{item[header.key].length}</>
                    ) : (
                      <>{item[header.key]}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </List>
        <Tooltip title="Add" placement="top-start">
          <Fab
            color="primary"
            aria-label="Add"
            className={classNames(classes.fab, classes.addButton)}
            onClick={this.handleAddProject}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: "100%",
      position: "relative",
      height: "calc(100vh - 90px)"
    },

    addButton: {
      color: "#fff",
      position: "fixed",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: 40
    },

    headerPadding: {
      padding: "10px 20px 10px 10px;",
      textAlign: "left"
    }
  });

export default withStyles(styles)(withTranslation()(ProjectsList));
