import React, { Component } from "react";
import { Theme, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../styles/colors";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { RouteComponentProps } from "react-router";
import List, { Header } from "../List";
import EndUserStore, { EndUser } from "../../stores/endUserStore";
import { inject, observer } from "mobx-react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Filters from "../Filters";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Female from "../../assets/sex/gender-female.svg";
import Male from "../../assets/sex/gender-male.svg";
import ProjectStore from "../../stores/projectStore";
import GroupStore from "../../stores/groupStore";
import AdminStore from "../../stores/adminStore";
import LocationStore from "../../stores/locationStore";
import { toJS } from "mobx";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DropZone from "../measurements/measurementsData/DropZone";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppStore from "../../stores/appStore";

interface MatchParams {}

interface EndUserListProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps
  extends EndUserListProps,
    RouteComponentProps<MatchParams> {
  endUserStore: EndUserStore;
  projectStore: ProjectStore;
  adminStore: AdminStore;
  appStore: AppStore;
  groupStore: GroupStore;
  locationStore: LocationStore;
  classes: any;
}

interface EndUserListState {
  filters: {
    [key: string]: string;
  };
  sortParams: {
    [key: string]: string;
  };
  openModal: boolean;
  modal: boolean;
}

@inject(
  "endUserStore",
  "groupStore",
  "locationStore",
  "projectStore",
  "adminStore",
  "appStore"
)
@observer
class EndUserList extends Component<EndUserListProps, EndUserListState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    filters: {},
    sortParams: { sort: "measurement.createdDate", order: "desc" },
    modal: true,
    openModal: false
  };

  getGroupsOptions = async (search: string) => {
    await this.p.groupStore.getGroupOptionsAutocompleteAction({ name: search });
  };

  getLocationsOptions = async (search: string) => {
    await this.p.locationStore.getLocationOptionsAutocompleteAction({
      name: search
    });
  };

  getProjectsOptions = async (search: string) => {
    await this.p.projectStore.getProjectOptionsAutocompleteAction({
      name: search
    });
  };
  getProjectList = () =>
    toJS(this.p.projectStore.projectOptionsAutocomplete).map(item => item);

  headers = [
    {
      name: this.p.t("END_USER_LIST.NAME"),
      key: "firstName"
    },
    {
      name: this.p.t("END_USER_LIST.SURNAME"),
      key: "lastName",
      sort: true
    },
    {
      name: this.p.t("END_USER_LIST.PID"),
      key: "pid"
    },

    {
      name: this.p.t("END_USER_LIST.GROUP"),
      key: "group"
    },
    {
      name: this.p.t("END_USER_LIST.LOCATION"),
      key: "location"
    },
    {
      name: this.p.t("END_USER_LIST.GENDER"),
      key: "gender"
    },

    {
      name: this.p.t("END_USER_LIST.CREATED_DATE"),
      key: "measurement.createdDate",
      sort: true
    },
    {
      name: this.p.t("END_USER_LIST.CREATED_BY"),
      key: "createdBy"
    },

    {
      name: this.p.t("END_USER_LIST.ORDER_NUMBER"),
      key: "externalId"
    },

    {
      name: this.p.t("END_USER_LIST.ORDER_STATUS"),
      key: "status"
    },

    {
      name: this.p.t("END_USER_LIST.INVOICE_DATE"),
      key: "invoiceDate"
    },

    {
      name: this.p.t("END_USER_LIST.CHOSEN_ID"),
      key: "chosenId"
    },
    {
      name: this.p.t("END_USER_LIST.CHOSEN_LABEL"),
      key: "chosenLabel"
    },

    {
      name: this.p.t("END_USER_LIST.SUGGESTED_LABEL"),
      key: "suggestedLabel"
    },

    {
      name: this.p.t("END_USER_LIST.BREASTS_LABEL"),
      key: "breastsLabel"
    },

    {
      name: this.p.t("END_USER_LIST.SUGGESTED_BREASTS_LABEL"),
      key: "suggestedBreastsLabel"
    },

    {
      name: this.p.t("END_USER_LIST.V"),
      key: "v"
    },

    {
      name: this.p.t("END_USER_LIST.BREASTS_UNDER"),
      key: "breastsUnder"
    },

    {
      name: this.p.t("END_USER_LIST.CHEST_WIDTH"),
      key: "chestWidth"
    },

    {
      name: this.p.t("END_USER_LIST.CHEST_HEIGHT"),
      key: "chestHeight"
    },

    {
      name: this.p.t("END_USER_LIST.WAIST"),
      key: "waist"
    },

    {
      name: this.p.t("END_USER_LIST.HEIGHT"),
      key: "height"
    },

    {
      name: this.p.t("END_USER_LIST.BACK_CHOSEN_LABEL"),
      key: "backChosenLabel"
    },

    {
      name: this.p.t("END_USER_LIST.COMMENT"),
      key: "remark"
    }
  ];

  getEndUserList = (sortParams: any) => {
    if (Object.keys(sortParams).length) {
      this.setState({ sortParams });
    }
    const sort = { ...this.state.sortParams, ...sortParams };
    const params = {
      ...sort,
      ...this.state.filters
    };
    this.p.endUserStore.getEndUserListAction(params);
  };

  handleAddEndUser = () => {
    this.p.history.push(`/end-users/add`);
  };

  handleOpenEndUser = (endUser: EndUser) => () => {
    this.p.history.push(`/end-users/details/${endUser.id}`);
  };

  handleChange = (filters: any) => {
    this.setState({ filters }, () => this.getEndUserList({ page: 0 }));
  };

  sendFile = async (data: any) => {
    const success = await this.p.endUserStore.uploadEndUserAction(data[0]);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.UPLOAD_ERROR"), {
        variant: "error"
      });
    } else {
      this.p.enqueueSnackbar(this.p.t("ERRORS.UPLOAD_SUCCESS"), {
        variant: "success"
      });
      this.setState({ openModal: false });
    }
  };

  getExportData = () => {
    this.p.endUserStore.getExportAction(this.state.filters);
  };

  openUploadModal = () => {
    this.setState({ openModal: true });
  };

  closeUploadModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const filters = [
      {
        name: "lastName",
        label: this.p.t("END_USER_LIST.SURNAME"),
        type: "input"
      },
      {
        name: "pid",
        label: this.p.t("END_USER_LIST.PID"),
        type: "input"
      },
      {
        name: "createdBy",
        label: this.p.t("END_USER_LIST.CREATED_BY"),
        type: "input"
      },
      {
        name: "orderNumber",
        label: this.p.t("END_USER_LIST.ORDER_NUMBER"),
        type: "input"
      },
      {
        name: "project",
        label: this.p.t("END_USER_LIST.PROJECT"),
        type: "autocomplete",
        getDataFunction: this.getProjectsOptions,
        options: this.p.projectStore.projectOptionsAutocomplete
      },
      {
        name: "group",
        label: this.p.t("END_USER_LIST.GROUP"),
        type: "autocomplete",
        getDataFunction: this.getGroupsOptions,
        options: this.p.groupStore.groupOptionsAutocomplete
      },
      {
        name: "location",
        label: this.p.t("END_USER_LIST.LOCATION"),
        type: "autocomplete",
        getDataFunction: this.getLocationsOptions,
        options: this.p.locationStore.locationOptionsAutocomplete
      },
      {
        name: "dateFrom",
        label: this.p.t("END_USER_LIST.DATE_FROM"),
        type: "datepicker"
      },
      {
        name: "dateTo",
        label: this.p.t("END_USER_LIST.DATE_TO"),
        type: "datepicker"
      }
    ];
    const {
      classes,
      t,
      endUserStore: { endUserList, endUsersTotalCount, pendingExport },
      appStore: { isFilter }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography
          className={classNames(classes.dividerFullWidth, "title")}
          display="block"
          variant="subtitle1"
        >
          {t("END_USER_LIST.TITLE")}
        </Typography>
        <Divider component="div" className={"divider"} />
        <Filters handleFilter={this.handleChange} filters={filters} />
        <Button
          className={classNames(classes.btnColor, classes.btnUpload)}
          onClick={this.openUploadModal}
        >
          {t(`UPLOAD_XLS`)}
        </Button>
        <Button
          className={classNames(classes.btnColor, classes.btnUpload)}
          onClick={this.getExportData}
          disabled={pendingExport}
        >
          <div>{t(`DOWNLOAD_EXPORT`)}</div>
          {pendingExport && (
            <CircularProgress size={20} thickness={5} color="secondary" />
          )}
        </Button>
        <List
          data={endUserList}
          getData={this.getEndUserList}
          headers={this.headers}
          totalCount={endUsersTotalCount}
          isFilter={isFilter && isFilter}
          headerClass={classes.headerPadding}
        >
          {endUserList &&
            endUserList.length &&
            endUserList.map((item: any, i: number) => (
              <TableRow
                key={item.uuid || item.id || i}
                className={"rowList"}
                onClick={this.handleOpenEndUser(item)}
              >
                {this.headers.map((header: Header) => {
                  switch (header.key) {
                    case "gender":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item[header.key] === "FEMALE" ? (
                            <img
                              className={classes.genderColor}
                              src={Female}
                              alt="female"
                            />
                          ) : item[header.key] === "MALE" ? (
                            <img
                              className={classes.genderColor}
                              src={Male}
                              alt="male"
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      );
                    case "project":
                    case "group":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item[header.key].name}
                        </TableCell>
                      );
                    case "location":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.location
                          )}
                        >
                          {item[header.key].name}
                        </TableCell>
                      );
                    case "chestHeight":
                    case "chestWidth":
                    case "chosenId":
                    case "chosenLabel":
                    case "height":
                    case "suggestedLabel":
                    case "waist":
                    case "backChosenId":
                    case "backChosenLabel":
                    case "breastsLabel":
                    case "breastsUnder":
                    case "suggestedBreastsLabel":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item && item.measurement[header.key]
                            ? item.measurement[header.key]
                            : "-"}
                        </TableCell>
                      );
                    case "remark":
                      return (
                        <Tooltip
                          title={
                            item.measurement[header.key] ? (
                              <React.Fragment>
                                <Typography color="inherit">
                                  {item.measurement[header.key]}
                                </Typography>
                              </React.Fragment>
                            ) : (
                              ""
                            )
                          }
                        >
                          <TableCell
                            key={header.key}
                            className={classNames(
                              classes.tableCell,
                              classes.remark
                            )}
                          >
                            {item && item.measurement[header.key]
                              ? item.measurement[header.key].slice(0, 40) +
                                "..."
                              : "-"}
                          </TableCell>
                        </Tooltip>
                      );
                    case "v":
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item
                            ? item.measurement[header.key]
                            : item.measurement[header.key] === 0
                            ? item.measurement[header.key]
                            : "-"}
                        </TableCell>
                      );
                    case "measurement.createdDate":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.createdDate
                          )}
                        >
                          {item && item.measurement["createdDate"]
                            ? item.measurement["createdDate"]
                            : "-"}
                        </TableCell>
                      );
                    case "createdBy":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.createdBy
                          )}
                        >
                          {item.measurement &&
                            item.measurement.createdBy.username}
                        </TableCell>
                      );
                    case "firstName":
                    case "lastName":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.nameCell,
                            "capitalizeText"
                          )}
                        >
                          {item[header.key]}
                        </TableCell>
                      );
                    case "externalId":
                    case "status":
                    case "invoiceDate":
                      return (
                        <TableCell
                          key={header.key}
                          className={classNames(
                            classes.tableCell,
                            classes.nameCell
                          )}
                        >
                          {item.order[header.key]}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell
                          key={header.key}
                          className={classes.tableCell}
                        >
                          {item[header.key]}
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
        </List>
        <Dialog
          open={this.state.openModal}
          PaperProps={{
            classes: {
              root: classes.dialog
            }
          }}
        >
          <DialogTitle className={classes.modalTitle}>
            {t("END_USER_LIST.UPLOAD_FILE")}
          </DialogTitle>
          <DialogContent>
            <DropZone
              send={this.sendFile}
              modal={this.state.modal}
              close={this.closeUploadModal}
            />
          </DialogContent>
        </Dialog>
        <Tooltip title="Add" placement="top-start">
          <Fab
            color="primary"
            aria-label="Add"
            className={classNames(classes.fab, classes.addButton)}
            onClick={this.handleAddEndUser}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: "100%",
      position: "relative",
      height: "calc(100vh - 90px)"
    },

    rowList: {
      cursor: "pointer"
    },

    addButton: {
      color: "#fff",
      position: "fixed",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: 40
    },
    genderColor: {
      color: COLORS.WHITE
    },

    btnColor: {
      color: COLORS.WHITE,
      background: COLORS.PRIMARY_DARK,
      marginTop: 20,
      marginLeft: 5,
      marginRight: 5
    },

    modalTitle: {
      textAlign: "center"
    },

    dialog: {
      width: "100%",
      background: COLORS.DIALOG_COLOR
    },

    tableCell: {
      padding: 5,
      textAlign: "center"
    },

    headerPadding: {
      padding: "0px 20px 0px 10px;",
      textAlign: "center"
    },

    location: {
      whiteSpace: "nowrap"
    },

    createdDate: {
      whiteSpace: "nowrap"
    },

    createdBy: {
      whiteSpace: "nowrap"
    },

    btnUpload: {
      marginBottom: 20
    },

    nameCell: {
      minWidth: 180,
      maxWidth: 200
    },

    invoiceDate: {
      minWidth: 140,
      maxWidth: 200
    },

    status: {
      minWidth: 100,
      maxWidth: 200
    },

    remark: {
      whiteSpace: "nowrap"
    },

    gender: {
      minWidth: 50,
      maxWidth: 70
    },

    measurement: {
      minWidth: 80,
      maxWidth: 120
    },

    tooltipFontSize: {
      fontSize: 20
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(EndUserList)));
