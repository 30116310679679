import React from "react";
import { useDropzone } from "react-dropzone";
import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../styles/colors";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: "center"
    },
    textColor: {
      color: COLORS.PRIMARY
    },
    btnColor: {
      color: COLORS.WHITE,
      background: COLORS.PRIMARY_DARK,
      marginTop: 20,
      width: "25%",
      maxWidth: 100
    },
    btnCancel: {
      color: COLORS.WHITE,
      background: COLORS.PRIMARY_DARK,
      marginTop: 20,
      width: "25%",
      marginLeft: 20,
      maxWidth: 100
    },
    dropZone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 20,
      width: "50%",
      margin: "auto",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: COLORS.PRIMARY,
      borderStyle: "dashed",
      backgroundColor: COLORS.DIALOG_COLOR,
      outline: "none",
      transition: "border .24s ease-in-out"
    }
  })
);

function DropZone(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple: false,
    accept: ".xlsx"
  });
  const files = acceptedFiles.map(file => <li key={file.name}>{file.name}</li>);
  return (
    <section className={classes.container}>
      <div {...getRootProps({ className: classes.dropZone })}>
        <input {...getInputProps()} />
        {files.length === 0 ? (
          <p className={classes.textColor}>{t("EXCEL")}</p>
        ) : (
          <ul className={classes.textColor}>{files}</ul>
        )}
      </div>
      <Button
        className={classes.btnColor}
        onClick={() => props.send(acceptedFiles)}
      >
        {t(`UPLOAD`)}
      </Button>
      {props.modal && (
        <Button className={classes.btnCancel} onClick={() => props.close()}>
          {t(`CANCEL`)}
        </Button>
      )}
    </section>
  );
}

export default DropZone;
