import { action, configure, observable } from "mobx";
import adminService from "../services/adminService";
import moment from "moment";

configure({ enforceActions: "observed" });

interface AdminBase {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  active: boolean | string;
}

export interface Admin extends AdminBase {
  id: string;
  username: string;
}

export type ImportList = {
  user: {
    username: string;
  };
  createdDate: string;
  status: string;
};

export default class AdminStore {
  // STORE
  @observable
  admin: Admin = {
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    username: "",
    active: false
  };
  @observable pending: boolean = false;

  @observable adminList: Admin[] = [];

  @observable importList: ImportList[] = [];

  @observable importTotalCount?: number;

  @observable adminsTotalCount?: number;

  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  // ACTIONS

  @action
  private setAdmin = (admin: Admin) => {
    this.admin = admin;
  };

  @action
  private setAdminList = (adminList: Admin[]) => {
    this.adminList = adminList;
  };

  @action
  private setImportList = (importList: ImportList[]) => {
    this.importList = importList.map((importList: ImportList) => ({
      user: {
        username: importList && importList.user.username
      },
      status: importList && importList.status,
      createdDate:
        importList && moment(importList.createdDate).format("DD/MM/YYYY")
    }));
  };

  @action
  private setImportTotalCount = (totalCount: number) => {
    this.importTotalCount = totalCount;
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.adminsTotalCount = totalCount;
  };

  @action
  resetAdmin = () => {
    this.setAdmin({
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      username: "",
      active: false
    });
  };

  @action
  getAdminListAction = async (params?: any) => {
    // TODO add params options
    this.pendingStart();
    const result = await adminService.getAdmins(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }

    this.setAdminList(result.users);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  addAdminAction = async (data: AdminBase) => {
    this.pendingStart();
    const result = await adminService.addAdmin({
      ...data,
      type: "ADMIN",
      username: data.email
    });
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getAdminListAction();
    return true;
  };

  @action
  getAdminByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await adminService.getAdminById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setAdmin(result.user);
    return true;
  };

  @action
  editAdminAction = async (data: AdminBase, id: string) => {
    this.pendingStart();
    const result = await adminService.editAdmin(
      { ...data, type: "ADMIN", username: data.email },
      id
    );
    this.pendingEnd();
    return !result.error;
  };

  @action
  deleteAdminAction = async (id: string) => {
    this.pendingStart();
    const result = await adminService.deleteAdmin(id);
    this.pendingEnd();
    return !result.error;
  };

  @action
  uploadFileAction = async (files: any) => {
    this.pendingStart();
    const result = await adminService.uploadFile(files);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  getFileListAction = async (params: any) => {
    this.pendingStart();
    const result = await adminService.getFile(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setImportList(result.tasks);
    this.setImportTotalCount(result.totalCount);
    return true;
  };
}
