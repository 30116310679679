import { action, configure, observable } from "mobx";
import accountService from "../services/accountService";

configure({ enforceActions: "observed" });

type Account = {
  name: string;
  phone: string;
  email: string;
};

export default class AccountStore {
  // STORE
  @observable user: Account = {
    name: "",
    phone: "",
    email: ""
  };

  @observable userImage: boolean = false;

  @observable pendingGet: boolean = false;

  @observable pending: boolean = false;

  @observable pendingDelete: boolean = false;

  // ACTIONS
  @action
  getAccountAction = async () => {
    this.pendingGet = true;
    const result = await accountService.getAccount();
    this.pendingGet = false;
    if (result.error) {
      return false;
    }
    this.user = {
      name: result.donor.name,
      phone: result.donor.phone,
      email: result.donor.email
    };
    return true;
  };

  @action
  deleteAccountAction = async () => {
    this.pending = true;
    const result = await accountService.deleteAccount();
    this.pending = false;
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  updateAccountAction = async (data: any) => {
    this.pending = true;
    const result = await accountService.updateAccount(data);
    this.pending = false;
    if (result.error) {
      return false;
    }
    this.getAccountAction();
    return true;
  };

  @action
  forgotPasswordAction = async (data: any) => {
    this.pending = true;
    const result = await accountService.forgotPassword(data);
    this.pending = false;
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  resetPasswordAction = async (data: any) => {
    this.pending = true;
    const result = await accountService.resetPassword(data);
    this.pending = false;
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  changePasswordAction = async (data: any) => {
    this.pending = true;
    const result = await accountService.changePassword(data);
    this.pending = false;
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  clearAccountAction = () => {
    this.user = {
      name: "",
      phone: "",
      email: ""
    };
  };
}
