import { action, configure, observable } from "mobx";
import endUserService from "../services/endUserService";
import { Group } from "./groupStore";
import { Project } from "./projectStore";
import { Location } from "./locationStore";
import moment from "moment";

configure({ enforceActions: "observed" });

export type EndUser = {
  id: string;
  firstName: string;
  lastName: string;
  pid: string;
  gender: string;
  group: Group;
  location: Location;
  project: Project;
  measurement: {
    chestHeight?: any;
    chestWidth?: any;
    chosenId?: string;
    chosenLabel?: string;
    height?: any;
    suggestedLabel?: string;
    v?: string;
    waist?: any;
    backChosenId?: string;
    backChosenLabel?: string;
    breastsLabel?: string;
    breastsUnder?: any;
    suggestedBreastsLabel?: string;
    remark?: string;
    createdDate?: string;
    createdBy: {
      username?: string;
    };
  };
  order: {
    externalId: string;
    status: string;
    invoiceDate: string;
  };
};

export default class EndUserStore {
  // STORE
  @observable
  endUser: EndUser = {
    id: "",
    firstName: "",
    lastName: "",
    pid: "",
    gender: "",
    group: {
      active: true,
      id: "",
      name: "",
      locations: []
    },
    location: {
      active: true,
      id: "",
      name: ""
    },
    project: {
      groups: [],
      id: "",
      locations: [],
      name: "",
      type: ""
    },
    measurement: {
      chestHeight: "",
      chestWidth: "",
      chosenId: "",
      chosenLabel: "",
      height: "",
      suggestedLabel: "",
      v: "",
      waist: "",
      backChosenId: "",
      backChosenLabel: "",
      breastsLabel: "",
      breastsUnder: "",
      suggestedBreastsLabel: "",
      createdDate: "",
      remark: "",
      createdBy: {
        username: ""
      }
    },
    order: {
      externalId: "",
      status: "",
      invoiceDate: ""
    }
  };

  @observable pending: boolean = false;

  @observable pendingExport: boolean = false;

  @observable endUserList: EndUser[] = [];

  @observable endUsersTotalCount?: number;

  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private pendingExportStart = () => {
    this.pendingExport = true;
  };
  @action
  private pendingExportEnd = () => {
    this.pendingExport = false;
  };

  // ACTIONS

  @action
  private setEndUser = (endUser: EndUser) => {
    this.endUser = {
      id: endUser.id,
      firstName: endUser.firstName,
      lastName: endUser.lastName,
      pid: endUser.pid,
      gender: endUser.gender,
      group: {
        active: endUser.group && endUser.group.active,
        id: endUser.group && endUser.group.id,
        name: endUser.group.name,
        locations: endUser.group.locations
      },
      location: {
        active: endUser.location && endUser.location.active,
        id: endUser.location && endUser.location.id,
        name: endUser.location.name
      },
      project: {
        groups: endUser.project.groups,
        id: endUser.project.id,
        locations: endUser.project.locations,
        name: endUser.project.name,
        type: endUser.project.type
      },
      measurement: {
        chestHeight: endUser.measurement && endUser.measurement.chestHeight,
        chestWidth: endUser.measurement && endUser.measurement.chestWidth,
        chosenId: endUser.measurement && endUser.measurement.chosenId,
        chosenLabel: endUser.measurement && endUser.measurement.chosenLabel,
        height: endUser.measurement && endUser.measurement.height,
        suggestedLabel:
          endUser.measurement && endUser.measurement.suggestedLabel,
        v: endUser.measurement && endUser.measurement.v,
        waist: endUser.measurement && endUser.measurement.waist,
        backChosenId: endUser.measurement && endUser.measurement.backChosenId,
        backChosenLabel:
          endUser.measurement && endUser.measurement.backChosenLabel,
        breastsLabel: endUser.measurement && endUser.measurement.breastsLabel,
        breastsUnder: endUser.measurement && endUser.measurement.breastsUnder,
        suggestedBreastsLabel:
          endUser.measurement && endUser.measurement.suggestedBreastsLabel,
        remark:
          endUser.measurement &&
          endUser.measurement.remark &&
          endUser.measurement.remark,
        createdDate:
          endUser.measurement &&
          moment(endUser.measurement.createdDate).format("DD/MM/YYYY HH:MM"),
        createdBy: {
          username:
            endUser.measurement && endUser.measurement.createdBy.username
        }
      },
      order: {
        externalId: endUser.order && endUser.order.externalId,
        status: endUser.order && endUser.order.status,
        invoiceDate:
          endUser.order &&
          moment(endUser.order.invoiceDate).format("DD/MM/YYYY")
      }
    };
  };

  @action
  private setEndUserList = (endUserList: EndUser[]) => {
    this.endUserList = endUserList.map((endUser: EndUser) => ({
      id: endUser.id,
      firstName: endUser.firstName,
      lastName: endUser.lastName,
      pid: endUser.pid,
      gender: endUser.gender,
      group: {
        active: endUser.group && endUser.group.active,
        id: endUser.group && endUser.group.id,
        name: endUser.group.name,
        locations: endUser.group.locations
      },
      location: {
        active: endUser.location && endUser.location.active,
        id: endUser.location && endUser.location.id,
        name: endUser.location && endUser.location.name
      },
      project: {
        groups: endUser.project.groups,
        id: endUser.project.id,
        locations: endUser.project.locations,
        name: endUser.project.name,
        type: endUser.project.type
      },
      measurement: {
        chestHeight:
          endUser.measurement && endUser.measurement.chestHeight / 100,
        chestWidth: endUser.measurement && endUser.measurement.chestWidth / 100,
        chosenId: endUser.measurement && endUser.measurement.chosenId,
        chosenLabel: endUser.measurement && endUser.measurement.chosenLabel,
        height: endUser.measurement && endUser.measurement.height / 100,
        suggestedLabel:
          endUser.measurement && endUser.measurement.suggestedLabel,
        v: endUser.measurement && endUser.measurement.v,
        waist: endUser.measurement && endUser.measurement.waist / 100,
        backChosenId: endUser.measurement && endUser.measurement.backChosenId,
        backChosenLabel:
          endUser.measurement && endUser.measurement.backChosenLabel,
        breastsLabel: endUser.measurement && endUser.measurement.breastsLabel,
        breastsUnder:
          endUser.measurement &&
          endUser.measurement.breastsUnder &&
          endUser.measurement.breastsUnder / 100,
        suggestedBreastsLabel:
          endUser.measurement && endUser.measurement.suggestedBreastsLabel,
        remark:
          endUser.measurement &&
          endUser.measurement.remark &&
          endUser.measurement.remark,
        createdDate:
          endUser.measurement &&
          moment(endUser.measurement.createdDate).format("DD/MM/YYYY HH:MM"),
        createdBy: {
          username:
            endUser.measurement && endUser.measurement.createdBy.username
        }
      },
      order: {
        externalId: endUser.order && endUser.order.externalId,
        status: endUser.order && endUser.order.status,
        invoiceDate:
          endUser.order &&
          moment(endUser.order.invoiceDate).format("DD/MM/YYYY")
      }
    }));
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.endUsersTotalCount = totalCount;
  };

  @action
  resetEndUser = () => {
    this.setEndUser({
      id: "",
      firstName: "",
      lastName: "",
      pid: "",
      gender: "",
      group: {
        active: true,
        id: "",
        name: "",
        locations: []
      },
      location: {
        active: true,
        id: "",
        name: ""
      },
      project: {
        groups: [],
        id: "",
        locations: [],
        name: "",
        type: ""
      },
      measurement: {
        chestHeight: "",
        chestWidth: "",
        chosenId: "",
        chosenLabel: "",
        height: "",
        suggestedLabel: "",
        v: "",
        waist: "",
        backChosenId: "",
        backChosenLabel: "",
        breastsLabel: "",
        breastsUnder: "",
        remark: "",
        suggestedBreastsLabel: "",
        createdBy: {
          username: ""
        }
      },
      order: {
        externalId: "",
        status: "",
        invoiceDate: ""
      }
    });
  };

  @action
  getEndUserListAction = async (params?: any) => {
    this.pendingStart();
    const result = await endUserService.getEndUsers(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setEndUserList(result.persons);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  getEndUserByPID = async (pid: string) => {
    this.pendingStart();
    const result = await endUserService.getEndUsers({ pid });
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return result.persons.find((person: EndUser) => person.pid === pid);
  };

  @action
  addEndUserAction = async (data: any) => {
    this.pendingStart();
    const result = await endUserService.addEndUser(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getEndUserListAction();
    return result.person.id;
  };

  @action
  getEndUserByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await endUserService.getEndUserById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setEndUser(result.person);
    return result.person;
  };

  @action
  editEndUserAction = async (params: any, id: string) => {
    this.pendingStart();
    const result = await endUserService.editEndUser(params, id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return id;
  };

  @action
  deleteEndUserAction = async (id: string) => {
    this.pendingStart();
    const result = await endUserService.deleteEndUser(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  uploadEndUserAction = async (files: any) => {
    this.pendingStart();
    const result = await endUserService.uploadEndUser(files);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
  @action
  getExportAction = async (data: any) => {
    this.pendingExportStart();
    const initResult = await endUserService.createExport(data);
    if (!initResult || initResult.error) {
      return false;
    } else {
      let interval: any;
      interval = setInterval(async () => {
        const status = await endUserService.getStatusExportFile(
          initResult.task.id
        );
        if (status.task.state === "SUCCESS") {
          const result = await endUserService.getExportFile(initResult.task.id);
          if (result && !result.error) {
            clearInterval(interval);
            this.saveData(result);
            this.pendingExportEnd();
          }
        }
      }, 2000);
    }
    return true;
  };

  saveData = (blob: any) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    // @ts-ignore
    a.style = "display: none";
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "end-users.xlsx";
    a.click();
    a.remove();
  };
}
