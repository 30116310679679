import { action, configure, observable } from "mobx";
import userService from "../services/accountService";
import { UserCredentials } from "./models/authStore.model";
import accountService from "../services/accountService";

configure({ enforceActions: "observed" });

export default class AuthStore {
  // STORE
  @observable session: any;
  @observable pending: boolean = false;

  @action
  private setSession = (value: any) => {
    this.session = value;
  };

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  // ACTIONS
  @action
  loginAction = async (credentials: UserCredentials) => {
    this.pendingStart();
    const result = await accountService.login(credentials);
    this.pendingEnd();
    if (result.error && result.status === 403) {
      this.logoutAction();
      return { error: true, accessDenied: true };
    }
    if (result.error) {
      this.logoutAction();
      return { error: true, accessDenied: false };
    }
    const session = {
      uuid: result.session.userId,
      userType: result.session.userType,
      xsrfToken: result.session.xsrfToken
    };
    this.setSession(session);
    sessionStorage.setItem("@sessionNFM", JSON.stringify(session));
    return result;
  };

  @action
  autoLoginAction = () => {
    this.session = AuthStore.getSession();
  };

  @action
  logoutAction = async () => {
    this.pendingStart();
    if (this.session && this.session.xsrfToken) {
      await userService.logout();
    }
    this.setSession(null);
    sessionStorage.removeItem("@sessionNFM");
    this.pendingEnd();
  };

  // UTILS
  static getSession() {
    const session = sessionStorage.getItem("@sessionNFM");
    if (session) {
      return JSON.parse(session);
    }
  }
}
