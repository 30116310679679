import i18n from "../config/i18n";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import UserIcon from "@material-ui/icons/AccountCircle";
import ProjectIcon from "@material-ui/icons/Assignment";
import MeasureIcon from "../assets/measure.png";
import GroupIcon from "@material-ui/icons/Group";
import LocationIcon from "@material-ui/icons/LocationCity";
import EndUserIcon from "@material-ui/icons/FolderShared";
import XLS from "@material-ui/icons/ViewModule";
import React, { ReactElement } from "react";

interface Route {
  name: string;
  path: string;
}
interface HomeRoute extends Route {
  icon: () => ReactElement;
}

export const HomeRouting: HomeRoute[] = [
  {
    name: i18n.t("ROUTES.ADMINS"),
    path: "/admins",
    icon: () => <AdminIcon />
  },
  {
    name: i18n.t("ROUTES.PROJECTS"),
    path: "/projects",
    icon: () => <ProjectIcon />
  },
  {
    name: i18n.t("ROUTES.GROUPS"),
    path: "/groups",
    icon: () => <GroupIcon />
  },
  {
    name: i18n.t("ROUTES.LOCATIONS"),
    path: "/locations",
    icon: () => <LocationIcon />
  },
  {
    name: i18n.t("ROUTES.USERS"),
    path: "/users",
    icon: () => <UserIcon />
  },
  {
    name: i18n.t("ROUTES.MEASUREMENTS"),
    path: "/measurements",
    icon: () => (
      <img
        style={{ maxWidth: "25px", width: "100%", height: "auto" }}
        src={MeasureIcon}
      />
    )
  },
  {
    name: i18n.t("ROUTES.SETTINGS"),
    path: "/settings",
    icon: () => <XLS />
  },
  {
    name: i18n.t("ROUTES.END_USERS"),
    path: "/end-users",
    icon: () => <EndUserIcon />
  }
];
