import REST from "../constants/rest";
import API from "./apiService";

export default class UserService {
  static async getUsers(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/users?type=MODERATOR${params ? "&" + urlSP.toString() : ""}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getUserById(id: string) {
    const path = `/users/${id}?include=projects`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addUser(data: any) {
    const path = "/users";
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editUser(params: any, id: string) {
    const path = `/users/${id}`;
    const options = {
      method: REST.POST,
      body: params
    };
    return API.fetch(path, options);
  }

  static async deleteUser(id: string) {
    const path = `/users/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }
}
