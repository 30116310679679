import REST from "../constants/rest";
import API from "./apiService";
import { API_URL } from "../config/api";

export default class EndUserService {
  static async getEndUsers(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/persons?include=group,project,location,creator${
      params ? "&" + urlSP.toString() : ""
    }`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getEndUserById(id: string) {
    const path = `/persons/${id}?include=group,project,location`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addEndUser(data: any) {
    const path = "/persons";
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editEndUser(params: any, id: string) {
    const path = `/persons/${id}`;
    const options = {
      method: REST.POST,
      body: params
    };
    return API.fetch(path, options);
  }

  static async deleteEndUser(id: string) {
    const path = `/persons/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }

  static async uploadEndUser(files: any) {
    const path = `/persons/imports`;
    const options = {
      method: REST.POST,
      body: files
    };
    return API.sendFile(path, options);
  }

  static async createExport(data: any) {
    const path = "/persons/exports";
    const options = {
      method: REST.POST,
      body: {
        parameters: data
      }
    };
    return API.fetch(path, options);
  }

  static async getExportFile(taskId: any) {
    const path = `${API_URL}/persons/exports/${taskId}/content`;
    return fetch(path, { credentials: "include" }).then((response: any) => {
      if (response.status === 200 || response.status === 0) {
        return response.blob();
      } else {
        return { error: "Cannot get file" };
      }
    });
  }

  static async getStatusExportFile(taskId: any) {
    const path = `/persons/exports/${taskId}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }
}
