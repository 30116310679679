const API_URL_PROD = "https://api.sizefit.nfm.no/v1/admin";
const API_URL_STG = "https://api.sizefit.maio.sh/v1/admin";
const API_URL_DEV = "https://api.sizefit.test.maio.sh/v1/admin";
function getApiUrl() {
  switch (process.env.REACT_APP_ENV) {
    case "prod":
      return API_URL_PROD;
    case "stage":
      return API_URL_STG;
    case "test":
    default:
      return API_URL_DEV;
  }
}

export const API_URL = getApiUrl();
