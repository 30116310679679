import REST from "../constants/rest";
import API from "./apiService";

export default class ProjectService {
  static async getProjects(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/projects${params ? "?" + urlSP.toString() : ""}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getProjectById(id: string) {
    const path = `/projects/${id}?include=groups,locations`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getGroup(ids: any) {
    const options = ids.map((uuid: string) => ({
      method: REST.GET,
      path: `/projects/${ids}?include=groups,locations`
    }));
    return API.fetchAll(options);
  }

  static async addProject(data: any) {
    const path = "/projects";
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editProject(params: any, id: string) {
    const path = `/projects/${id}`;
    const options = {
      method: REST.POST,
      body: params
    };
    return API.fetch(path, options);
  }

  static async deleteProject(id: string) {
    const path = `/projects/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }
}
