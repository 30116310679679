import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { COLORS } from "../../styles/colors";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithTranslation, withTranslation } from "react-i18next";
import MeasurementStore from "../../stores/measurementStore";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import MeasurementSummary from "./MeasurementSummary";
import EndUserStore from "../../stores/endUserStore";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { DialogComponent } from "../DialogComponent";

interface MatchParams {
  id: string;
}
interface MeasurementState {
  open: boolean;
}

interface MeasurementProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps
  extends MeasurementProps,
    RouteComponentProps<MatchParams> {
  measurementStore: MeasurementStore;
  endUserStore: EndUserStore;
  classes: any;
}

@inject("measurementStore", "endUserStore")
@observer
class MeasurementAdd extends Component<MeasurementProps, MeasurementState> {
  get p() {
    return this.props as InjectedProps;
  }
  state = { open: false };

  componentDidMount(): void {
    this.getData();
  }

  getData = async () => {
    const result = await this.p.measurementStore.getMeasurementByIdAction(
      this.p.match.params.id
    );
    if (!result) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.GLOBAL_ERROR"), {
        variant: "error"
      });
    } else {
      const success = await this.p.endUserStore.getEndUserByIdAction(
        result.person.id
      );
      if (!success) {
        this.p.enqueueSnackbar(this.p.t("ERRORS.GLOBAL_ERROR"), {
          variant: "error"
        });
      }
    }
  };

  deleteMeasurement = async () => {
    const success = await this.p.measurementStore.deleteMeasurementAction(
      this.p.match.params.id
    );

    if (!success) {
      this.p.enqueueSnackbar(this.p.t("ERRORS.CANT_DELETE_MEASUREMENT"), {
        variant: "error"
      });
    } else {
      this.p.enqueueSnackbar(this.p.t("MEASUREMENT.DELETE_SUCCESS"), {
        variant: "success"
      });
      this.p.history.replace("/measurements");
    }
  };
  backToList = () => {
    this.p.history.goBack();
  };
  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  render() {
    const {
      classes,
      t,
      endUserStore: { endUser },
      measurementStore: { measurement }
    } = this.p;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <BackIcon className={"backList"} onClick={this.backToList} />
          <MeasurementSummary
            variant="details"
            values={measurement}
            endUser={endUser}
          />
          <div>
            <Button
              aria-label="Delete"
              className={classNames(classes.button, classes.btn)}
              onClick={this.handleClickOpenDialog}
            >
              {t("DELETE")}
            </Button>
          </div>
        </div>
        <DialogComponent
          title={"MEASUREMENT.DELETE"}
          t={t}
          classes={classes}
          submitButtonTitle={"DELETE"}
          cancel={"CANCEL"}
          pending={false}
          handleClickCloseDialog={this.handleCloseDialog}
          handleDelete={this.deleteMeasurement}
          open={this.state.open}
        />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    content: {
      color: COLORS.WHITE,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      "@media (max-width:500px)": {
        flexDirection: "column"
      }
    },
    subtitle: {
      marginLeft: "-10px !important"
    },
    divider: {
      marginBottom: 15,
      marginLeft: "-10px !important"
    },
    btnColor: {
      color: COLORS.WHITE,
      "&:hover": {
        background: COLORS.PRIMARY_DARK
      }
    }
  });

export default withStyles(styles)(
  withTranslation()(withSnackbar(MeasurementAdd))
);
