import { action, configure, observable, toJS } from "mobx";
import groupService from "../services/groupService";
import { Location } from "./locationStore";

configure({ enforceActions: "observed" });

interface GroupBase {
  name: string;
  active: boolean;
}

interface GroupOption {
  label: string;
  value: string;
}

export interface Group extends GroupBase {
  id: string;
  locations: Location[];
}

export interface GroupLI extends GroupBase {
  id: string;
  locations: string[];
}

export default class GroupStore {
  // STORE
  @observable group: Group = {
    id: "",
    name: "",
    active: true,
    locations: []
  };

  @observable pending: boolean = false;

  @observable groupList: GroupLI[] = [];
  @observable groupOptionsAutocomplete: GroupOption[] = [];
  @observable location: any = [];
  @observable locationList: any = [];
  @observable locationTab: any = [];
  @observable locationGroup: any = [];
  @observable countGroup: any = [];
  @observable groupsTotalCount?: number;

  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.groupsTotalCount = totalCount;
  };

  // ACTIONS

  @action
  private setGroup = (group: Group) => {
    this.group = group;
  };

  @action
  resetGroup = () => {
    this.group = {
      id: "",
      name: "",
      active: true,
      locations: []
    };
  };

  @action
  private setGroupList = (groupList: GroupLI[]) => {
    this.groupList = groupList;
  };

  @action
  private setGroupOptions = (groupList: GroupLI[]) => {
    this.groupOptionsAutocomplete = groupList.map((item: GroupLI) => ({
      label: item.name,
      value: item.id
    }));
  };

  @action
  private setLocationOptions = (groupList: GroupLI[]) => {
    this.location = groupList.map((item: GroupLI) => ({
      label: item.name,
      value: item.id
    }));
  };

  @action
  getGroupListAction = async (params?: any) => {
    this.pendingStart();
    const result = await groupService.getGroups(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }

    this.setGroupList(result.groups);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  getGroupOptionsAutocompleteAction = async (params?: any) => {
    this.pendingStart();
    const result = await groupService.getGroups(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setGroupOptions(result.groups);
    return true;
  };

  @action
  addGroupAction = async (data: any) => {
    this.pendingStart();
    const result = await groupService.addGroup(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    // this.getGroupListAction();
    return true;
  };

  @action
  setCountGroup = (params: any) => {
    this.countGroup = params;
    if (this.countGroup.length < 1) {
      this.setLocation([]);
    }
    this.countGroup.map((item: any) => this.getListGroupByIdAction(item));
  };

  @action
  setLocationGroup = (params: any) => {
    this.locationGroup = params;
    this.getListGroupByIdAction(params);
  };

  @action
  getListGroupByIdAction = async (id: string) => {
    this.pendingStart();
    this.locationTab = [];
    this.setLocation(this.locationTab);

    const result = await groupService.getGroupById(id);
    result.group &&
      result.group.locations.forEach((item: any) => {
        if (
          !this.locationTab.find((location: any) => location.value === item.id)
        ) {
          this.locationTab.push({
            label: item.name,
            value: item.id,
            groupName: result.group.name
          });
        }
      });
    this.setLocation(this.locationTab);
  };

  @action
  getGroupByIdAction = async (id: string, locationList?: boolean) => {
    this.pendingStart();
    const result = await groupService.getGroupById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setGroup(result.group);
    return true;
  };

  @action
  private setLocation = (locationList: Location[]) => {
    this.locationList = locationList;
  };

  @action
  getLocationAction = (params: any) => {
    this.location = params;
  };

  @action
  editGroupAction = async (params: GroupBase, id: string) => {
    this.pendingStart();
    const result = await groupService.editGroup({ ...params }, id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  deleteGroupAction = async (id: string) => {
    this.pendingStart();
    const result = await groupService.deleteGroup(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
}
