import React, { Component } from "react";
import { FormControlLabel, Theme, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation, WithTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import { Field, Form, Formik, FormikActions } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import { InjectedNotistackProps, withSnackbar } from "notistack";
import * as Yup from "yup";
import { StringValidator } from "../../../utils/validators";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from "../../../styles/colors";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import Matrix from "../Matrix";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import MatrixBack from "../MatrixBack";
import MeasurementStore from "../../../stores/measurementStore";
import CustomSelect from "../../CustomSelectComponent";
import Check from "@material-ui/icons/RadioButtonChecked";

interface MatchParams {
  id: string;
}

interface SizeSelectionStepProps
  extends WithTranslation,
    InjectedNotistackProps {
  handleNext: (event: any) => void;
  handleBack: () => void;
  handleCancel: () => void;
  gender: string;
  values: SizeSelectionStepForm;
}

interface InjectedProps
  extends SizeSelectionStepProps,
    RouteComponentProps<MatchParams> {
  measurementStore: MeasurementStore;
  classes: any;
}

interface SizeSelectionStepState {
  edit: boolean;
  backSize: boolean;
}

export class SizeSelectionStepForm {
  constructor(values?: any) {
    if (values) {
      this.breastSize = values.breastSize || "";
      this.selectedSize = values.selectedSize || "";
      this.selectedBackSize = values.selectedBackSize || "";
      this.remark = values.remark || "";
    }
  }

  breastSize: string = "";
  selectedSize: string = "";
  selectedBackSize: string = "";
  remark: string = "";
}

const SizeSelectionStepSchema = Yup.object().shape<SizeSelectionStepForm>({
  breastSize: Yup.string(),
  selectedSize: StringValidator,
  selectedBackSize: Yup.string(),
  remark: Yup.string()
});

@inject("measurementStore")
@observer
class SizeSelectionStep extends Component<
  SizeSelectionStepProps,
  SizeSelectionStepState
> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    edit: false,
    backSize: false
  };

  submit = async (values: SizeSelectionStepForm) => {
    this.p.handleNext(values);
  };

  handleBackCheckbox = () => {
    this.setState((prevState: SizeSelectionStepState) => ({
      backSize: !prevState.backSize
    }));
  };

  render() {
    const {
      classes,
      t,
      values,
      measurementStore: { suggestion }
    } = this.p;
    const isIE = window.navigator.userAgent.indexOf("Trident") != -1;
    const { backSize } = this.state;
    return (
      <div className={classes.stepContainer}>
        {suggestion && suggestion.recommended.length && (
          <Formik
            enableReinitialize
            initialValues={
              new SizeSelectionStepForm(
                values.selectedSize
                  ? values
                  : {
                      breastSize: suggestion.breastRecommended,
                      selectedSize: suggestion.recommended
                    }
              )
            }
            validationSchema={SizeSelectionStepSchema}
            onSubmit={(
              values: SizeSelectionStepForm,
              actions: FormikActions<SizeSelectionStepForm>
            ) => {
              this.submit(values).then(() => {
                actions.setSubmitting(false);
              });
            }}
            onReset={values => {
              values = new SizeSelectionStepForm();
            }}
          >
            {props => {
              const {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                values,
                errors
              } = props;
              return (
                <Form noValidate className={classes.formWidth}>
                  <div className={classes.suggestForm}>
                    <div className={isIE ? classes.matrix : ""}>
                      <Matrix
                        recommended={suggestion.recommended}
                        suggestions={suggestion.suggestions}
                        matrix={suggestion.matrix}
                        onChange={(selected: string) => {
                          setFieldValue("selectedSize", selected);
                          setFieldValue("selectedBackSize", selected);
                          setFieldTouched("selectedSize");
                        }}
                        value={values.selectedSize}
                      />
                    </div>
                    <div className={isIE ? classes.backSizeSelect : ""}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={backSize}
                            className={classes.backSize}
                            onChange={this.handleBackCheckbox}
                            value="back"
                            color="primary"
                          />
                        }
                        label={t("MEASUREMENT.SELECT_BACK_SIZE")}
                      />
                      {backSize && (
                        <MatrixBack
                          recommended={values.selectedSize}
                          value={values.selectedBackSize}
                          onChange={(selected: string) => {
                            setFieldValue("selectedBackSize", selected);
                            setFieldTouched("selectedBackSize");
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className={classes.breasts}>
                    {this.p.gender === "FEMALE" && (
                      <div className={classes.breastSize}>
                        <InputLabel className={classes.label}>
                          {t("MEASUREMENT.BREAST_SIZE")}
                        </InputLabel>
                        <Field
                          name="breastSize"
                          className={classes.radioRow}
                          component={RadioGroup}
                        >
                          <FormControlLabel
                            value="A-B"
                            control={
                              <Radio
                                checkedIcon={<Check />}
                                disabled={isSubmitting}
                              />
                            }
                            label="A-B"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="C-D"
                            control={
                              <Radio
                                checkedIcon={<Check />}
                                disabled={isSubmitting}
                              />
                            }
                            label="C-D"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="E+"
                            control={
                              <Radio
                                checkedIcon={<Check />}
                                disabled={isSubmitting}
                              />
                            }
                            label="E+"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </div>
                    )}
                  </div>
                  <div className={classes.remark}>
                    <Field
                      id="remark"
                      name="remark"
                      label={t("MEASUREMENT.REMARK")}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.formControl
                        },
                        multiline: true
                      }}
                      component={TextField}
                      className={classes.textField}
                      margin="normal"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.btnContainer}>
                    <div className={classes.leftButton}>
                      <Button
                        onClick={this.p.handleCancel}
                        className={classes.button}
                      >
                        {t("CANCEL")}
                      </Button>
                    </div>
                    <div className={classes.rightButton}>
                      <Button
                        onClick={this.p.handleBack}
                        className={classes.button}
                      >
                        {t("BACK")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!values.selectedSize}
                        className={classes.buttonNext}
                      >
                        {t("NEXT")}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: "#fff",
      position: "absolute",
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + "!important"
    },

    formControl: {
      background: "rgba(255, 255, 255, 0.12)"
    },

    stepContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },

    textField: {
      width: "100%",
      maxWidth: 380
    },

    btnContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "calc(100% - 372px)",
      position: "fixed",
      bottom: 40,
      "@media (max-width:1200px)": {
        padding: 20,
        position: "relative",
        bottom: 0,
        width: "100%"
      }
    },

    sizeSelectionStep: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginBottom: 25
    },
    label: {
      marginBottom: 10
    },

    backSize: {
      padding: 0
    },

    suggestForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 20
    },

    breastSize: {
      textAlign: "center"
    },

    radioRow: {
      flexDirection: "column"
    },

    radioRowIe: {
      flexDirection: "column",
      marginLeft: "-24px"
    },

    remark: {
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },

    leftButton: {
      flex: 1
    },

    rightButton: {
      flex: 1,
      textAlign: "right",
      "@media (max-width:500px)": {
        display: "flex",
        flexDirection: "row"
      }
    },

    button: {
      width: "20%"
    },

    buttonNext: {
      marginLeft: 20,
      width: "20%",
      "@media (max-width:1200px)": {
        marginLeft: 10
      }
    },

    matrix: {
      height: 250
    },

    backSizeSelect: {
      height: 200
    },

    breasts: {
      display: "flex",
      justifyContent: "center"
    },

    formWidth: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    }
  });

export default withStyles(styles)(
  withTranslation()(withSnackbar(SizeSelectionStep))
);
