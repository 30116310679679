import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { OutlinedInput } from "@material-ui/core";
import { COLORS } from "../styles/colors";

const useStyles = makeStyles(() =>
  createStyles({
    customSelect: {
      height: 56
    },
    select: {
      width: "100%",
      maxWidth: 200,
      height: 56,
      marginTop: 12,
      borderRadius: 5,
      borderColor: COLORS.WHITE,
      background: "rgba(255, 255, 255, 0.12)",
      marginRight: 20,
      "@media (max-width:500px)": {
        marginRight: 0,
        maxWidth: 300
      }
    },
    notchedOutline: {
      borderWidth: 1
    },

    selectLabel: {
      marginLeft: 14,
      marginTop: -5
    }
  })
);

interface SelectFilterProps {
  value: any;
  onChange: Function;
  id: string;
  name: string;
  label: string;
}

const SelectFilter: FunctionComponent<SelectFilterProps> = props => {
  const classes = useStyles();
  const { value, onChange, id, name, label } = props;

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    onChange(event);
  }

  return (
    <FormControl className={classes.select}>
      <InputLabel className={classes.selectLabel}>{label}</InputLabel>
      <Select
        input={
          <OutlinedInput
            classes={{ notchedOutline: classes.notchedOutline }}
            labelWidth={50}
            name="status"
          />
        }
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {props.children}
      </Select>
    </FormControl>
  );
};

export default SelectFilter;
